import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Button } from "@mui/material";
import Form from 'react-bootstrap/Form';
import Modal from "react-bootstrap/Modal";
import { Edit } from "@mui/icons-material";
import ContactService from "../../services/contact-service";
import moment from "moment";

const EditNc = (props) => {
  const initialState = {
    firstname: props.nc.firstname,
    lastname: props.nc.lastname,
    telephone: props.nc.telephone,
    email: props.nc.email,
    birthdate: props.nc?.birthdate,
    est_mineur: props.nc.est_mineur,
    genre: props.nc?.genre,
    civilite: props.nc?.civilite,
    metier: props.nc.metier,
    liste_id: props.nc.liste?.id,
    liste_libelle: props.nc.liste?.libelle,
    liste: props.nc?.liste,
    source_id: props.nc.source.id,
    source_libelle: props.nc.source?.libelle,
    source: props.nc?.source,
    secteur_gf: props.nc?.secteur_gf,
    ville: props.nc?.ville,
    code_postal: props.nc.code_postal,
    adresse_postale: props.nc.adresse_postale,
    subscription_date: props.nc.subscription_date,
  };

  const [nc, setNc] = useState(initialState);
  const [listes, setListes] = useState([]);
  const [sources, setSources] = useState([]);
  const [secteurs, setSecteurs] = useState([]);
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  }
  const handleShow = () => setShow(true);

  useEffect(() => {
    setListes(props.listes);
    setSecteurs(props.secteurs);
    setSources(props.sources);
  }, [props.listes, props.secteurs, props.sources]);
  
  const handleChange = (event) => {
    event.preventDefault();
    const name = event.target.name;
    const value = event.target.value;
    let data = { ...nc, [name]: value };
    setNc(data);
  };

  const handleUpdateSubmit = (e) => {
    e.preventDefault();
    const putContacts = { ...nc };
    putContacts.subscription_date = moment(nc.subscription_date).format("yyyy-MM-DD");
    putContacts.birthdate = moment(nc.birthdate).format("yyyy-MM-DD");
    ContactService.update(props.id.id, putContacts)
      .then((data) => {
        toast.success("Modification réussie!", { autoClose: 2000 });
        props.sendUpdate(true);
      })
      .catch((e) => {
        toast.error("Problème observé lors de la modification!", {
          autoClose: 2000,
          onClose: () => {
            // window.location.reload(); // Actualisez la page lorsque le toast est fermé
          },
        });
      });
  };
  return (
    <>
      <Button variant="contained" color="warning" onClick={handleShow} className="m-1 text-white">
        <Edit />
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className="close">
          <Modal.Title>Modification du nouveau converti</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form onSubmit={handleUpdateSubmit}>
            <div className="row">
              <div className="form-group col">
                <label htmlFor="civile">Civilité:</label>
                <select
                  className="form-control"
                  id="civile"
                  name="civilite"
                  value={nc.civilite}
                  onChange={handleChange}
                >
                  <option hidden>{nc.civilite}</option>
                  <option value="madame">Madame</option>
                  <option value="mademoiselle">Mademoiselle</option>
                  <option value="monsieur">Monsieur</option>
                </select>
              </div>
              <div className="form-group col">
                <label htmlFor="nom">Nom:*</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Entrer le nom"
                  id="lastname"
                  name="lastname"
                  required
                  value={nc.lastname}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col">
                <label htmlFor="prenom">Prénom:*</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Entrer le prénom"
                  id="prenom"
                  name="firstname"
                  required
                  value={nc.firstname}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <label htmlFor="email">Email adresse:</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Entrer l'email adresse"
                  id="email"
                  name="email"
                  value={nc.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group col">
                <label htmlFor="tel">Téléphone*</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Entrer le téléphone"
                  id="tel"
                  pattern="^\d{10}$"
                  name="telephone"
                  value={nc.telephone}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <label htmlFor="est_mineur">Ado/Adulte:</label>
                <select
                  className="form-control"
                  id="est_mineur"
                  name="est_mineur"
                  value={nc.est_mineur}
                  onChange={handleChange}
                >
                  <option hidden></option>
                  <option value="0">Ado</option>
                  <option value="1">Adulte</option>
                </select>
              </div>
              <div className="form-group col">
                <label htmlFor="date">Date de naissance</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Entrer la date de naissance"
                  id="date"
                  name="birthdate"
                  defaultValue={nc.birthdate}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col">
                <label htmlFor="tel">Métier</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Entrer le métier"
                  id="metier"
                  name="metier"
                  value={nc.metier}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <label htmlFor="date">Inscription*</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Entrer le téléphone"
                  id="subscription_date"
                  name="subscription_date"
                  value={
                    nc.subscription_date ? nc.subscription_date : new Date()
                  }
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col">
                <label htmlFor="source">Secteur:</label>
                <select
                  className="form-control"
                  id="secteur_gf"
                  name="secteur_gf"
                  value={nc.secteur_gf}
                  onChange={handleChange}
                >
                  {Array.isArray(secteurs) && secteurs.map((s, index) => (
                    <option key={index} value={s}>
                      {s}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-4">
                <label htmlFor="ville">Ville:</label>
                <input
                  className="form-control"
                  id="ville"
                  name="ville"
                  placeholder="Entre la ville ..."
                  value={nc.ville}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-3">
                <label htmlFor="code_postal">Code Postal:</label>
                <input
                  className="form-control"
                  id="code_postal"
                  name="code_postal"
                  placeholder="Entre le code postal"
                  value={nc.code_postal}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-5">
                <label htmlFor="adresse_postal">Adresse:</label>
                <input
                  className="form-control"
                  id="adresse_postal"
                  name="adresse_postale"
                  placeholder="Entre le code postal"
                  value={nc.adresse_postale}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <label htmlFor="est_mineur">Liste:</label>
                <select
                  className="form-control"
                  id="liste_libelle"
                  name="liste_libelle"
                  value={nc.liste_id}
                  onChange={handleChange}
                >
                  <option hidden>Choisir ...</option>
                  { Array.isArray(listes) && listes.map((l) => (
                    <option key={l.id} value={l.id}>
                      {l.libelle}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group col">
                <label htmlFor="source">Source:</label>
                <select
                  className="form-control"
                  id="source_id"
                  name="source_id"
                  value={nc.source_id}
                  onChange={handleChange}
                  required
                >
                  <option hidden>Choisir ...</option>
                  {Array.isArray(sources) && sources.map((s) => (
                    <option key={s.id} value={s.id}>
                      {s.libelle}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <ToastContainer autoClose={3000} />
          </Form>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="contained" color="primary" onClick={handleUpdateSubmit} className="ml-1 mr-1">
            Modifier
          </Button>
          <Button variant="contained" color="error" onClick={handleClose}  className="ml-1 mr-1">
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditNc;
