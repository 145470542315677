import api from "./api";
import axios from "axios";

const list = async () => {
  return await api.get("/v1/users").then((response) => response);
};
const add = async (user) => {
  return await api.post("/v1/users", user).then((response) => response);
};
const remove = async id => {
  return await api.delete(`/v1/users/${id}`)
              .then((response) => response);
};

const edit = async (id) => {
  return await api.get(`/v1/users/${id}`)
              .then((response) => response);
};
const update = async (id, user) => {
  return await api.put(`/v1/users/${id}`, user)
              .then((response) => console.log(response));
};

const getCodePostalByTown = async (ville) => {
   await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
     params: {
       address: ville,
       key: 'AIzaSyBP8tsRPsDp5KSspH95TzTCNDEliyAN9Lg', // Remplacez par votre clé d'API Google Maps
     },
   })
   .then((response) =>  response.data.results
   )
   .catch((error) => {
     console.error('Erreur de géocodage :', error);
   });
}


const UserService = {
  list,
  add,
  edit,
  update,
  remove,
  getCodePostalByTown
};
export default UserService;
