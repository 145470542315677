import React, { useState, useEffect } from "react";
import UserService from "../../services/user-service";
import AddUser from "./add-user";
import DataTable from "../../components/layouts/data-table";

const ListUsers = () => {
  const initialState = [];
  const [users, setUsers] = useState(initialState);
  useEffect(() => {
    UserService.list().then((datas) => {
      console.log(datas.data.data);
      const newArray = datas.data.data.map(a => ({...{id: a.id, name: a.name,firstname:a.firstname,email:a.email,genre:a.genre,mobile_phone:a.mobile_phone,civilite:a.civilite,civil_status:a.civil_status,roles:a.roles.map(b=>`${b.title}`),is_active:a.is_active?'Activé':'Désactivé'}}));
      setUsers(newArray);
    });
  }, []);
  console.log(users)
  const handlClick = (id) => {
    console.log(id);
    UserService.remove(id).then((data) => console.log(data));
  };
 
  return (
    <>
      <div id="main-content">
        <div className="block-header">
          <div className="row clearfix">
            <div className="col-md-6 col-sm-12">
              <h2>Gestion utilisateurs</h2>
            </div>
            <div className="col-md-6 col-sm-12 text-right">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="index.html">
                    <i className="icon-home"></i>
                  </a>
                </li>
                <li className="breadcrumb-item active">Liste utilisateurs</li>
              </ul>
            </div>
          </div>
        </div>
        <AddUser />
        <div className="container-fluid">
          <div className="row clearfix">
            <div className="col-lg-12">
              <div className="card">
                <div className="header">
                  <h2>
                    Liste des utilisateurs{" "}
                  </h2>
                </div>
                <div className="body">
                  <div className="table-responsive">
                    <div className="text-right m-1">
                      <button
                        className="btn btn-sm btn-primary"
                        data-toggle="modal"
                        data-target="#exampleModal"
                      >
                      <i className="fa fa-plus"></i>
                          Ajouter
                      </button>
                    </div>
                    <DataTable users = {users} /> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListUsers;
