import React, { useState, useRef, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ConfigService from "../../services/config-service";
import ContactService from "../../services/contact-service";
import SuiviContactService from "../../services/suivi-contacts-service";
import { ToastContainer, toast } from "react-toastify";
import QueueIcon from "@mui/icons-material/Queue";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

export default function AlertDialog({sendUpdate}) {
  const [listeCoaches, setListeCoaches] = useState([]);
  const [coach, setCoach] = useState({});
  const [isNext, setGoNext] = useState(false);
  const [totalByCoach, setTotalByCoach] = useState([]);
  const [openDialog, setOpen] = React.useState(false);
  const [doUpdate, setDoUpdate] = useState(true);
  const [contacts, setContacts] = useState([]);
  const [listeAttributions, setAttribution] = useState([]);

  const coachListRef = useRef();

  const handleSelectCoach = (e) => {
    e.preventDefault();
    let selectedCoach = listeCoaches.find(
      (el) => parseInt(el.id) === parseInt(coachListRef.current.value)
    );
    setCoach(selectedCoach);
  };

  const handleGoNext = (e) => {
    e.preventDefault();
    if (Object.keys(coach).length === 0) {
      toast.error("Vous devez selectionner un coach");
      return;
    }
    if (listeAttributions.length === 0) {
      toast.error("Vous devez selectionner des contacts");
      return;
    }
    setGoNext(true);
  };

  const handleGoPrevious = (e) => {
    e.preventDefault();
    setGoNext(false);
  };

  const handleOpenDialog = () => {
    setOpen(true);
    setDoUpdate(true);
  };

  useEffect(() => {
    if (doUpdate) {
      setContacts([]);
      ConfigService.getNbNcMax().then((data) => {
        setTotalByCoach(data[0].value);
      });
      ConfigService.getNcByCoach().then((data) => setListeCoaches(data));
      ContactService.getContactAttenteAttr().then((data) => {
        data.data.map((d) => {
          d.checked = false;
          setContacts((previousData) => [...previousData, d]);
          return true;
        });
      });
      setDoUpdate(false);
    }
  }, [doUpdate]);

  const handleCloseDialog = () => {
    setCoach({});
    setOpen(false);
    setGoNext(false);
    setAttribution([]);
    setContacts([]);
  };

  const handleChangeCheck = (contact, e) => {
    e.persist();
    if (e.target.checked) {
      if (Object.keys(coach).length === 0) {
        toast.error("Vous devez selectionner un coach");
        e.target.checked = false;
        return;
      }

      if (totalByCoach - coach.total_suivi <= 0) {
        toast.error("Ce coach ne peut plus recevoir de nouveaux NC");
        e.target.checked = false;
        return;
      }

      contact.status = "En attente";
      contact.checked = true;
      setCoach({ ...coach, total_suivi: coach.total_suivi + 1 });
      setAttribution((previousListe) => [...previousListe, contact]);
    } else {
      contact.checked = false;
      setAttribution((previousListe) =>
        previousListe.filter((c) => c.id !== contact.id)
      );
      setCoach({ ...coach, total_suivi: coach.total_suivi - 1 });
    }
  };

  const handleAffectationClick = (e) => {
    e.preventDefault();

    listeAttributions.map((contact) => {
      SuiviContactService.add({
        contact_id: contact.id,
        coach_id: coach.id,
        date_debut: moment().format("yyyy-MM-DD"),
        date_fin: null,
        commentaires: "",
      })
        .then((response) => {
          contact.status = "Succès";
          setAttribution((previous) => [
            ...previous.filter((c) => c.id !== contact.id),
            contact,
          ]);
          setContacts((previousListe) =>
            previousListe.filter((c) => c.id !== contact.id)
          );
          toast.success(response.data.description, { autoClose: 2000 });
        })
        .catch((error) => {
          contact.status = "Echec";
          console.log(error.response.data.errors?.contact_id[0]);
          setAttribution((prevData) => [
            ...prevData.filter((c) => c.id !== contact.id),
            contact,
          ]);
          setContacts((previousListe) =>
            previousListe.filter((c) => c.id !== contact.id)
          );
          if (error.code === "ERR_BAD_REQUEST") {
            let contact_error =
              error.response.data.errors?.contact_id === undefined
                ? ""
                : error.response.data.errors?.contact_id[0];
            let coach_error =
              error.response.data.errors?.coach_id === undefined
                ? ""
                : error.response.data.errors?.coach_id[0];
            toast.error(
              <div>
                {contact_error}
                {contact_error !== "" && coach_error && <br />}
                {coach_error}
              </div>,
              { autoClose: 2000 }
            );
          } else {
            toast.error(error.response.data.description, { autoClose: 2000 });
          }
        });
      setDoUpdate(true);
      sendUpdate(true);
      return true;
    });
  };

  return (
    <div>
      <Button
        variant="contained"
        color="secondary"
        startIcon={<QueueIcon />}
        onClick={handleOpenDialog}
      >
        Attribution
      </Button>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          className="text-white bg-secondary text-center"
        >
          {"Attribution coach"}
        </DialogTitle>
        <DialogContent>
          {!isNext && (
            <DialogContentText id="alert-dialog-description" component={"div"}>
              <div className="sticky-top header-prog31">
                <div className="row mt-0 ml-0 mr-0 pt-2 mb-3">
                  <div className="col-12">
                    <form className="mt-2 pl-2 pr-2">
                      <div className="row mb-1 pb-1">
                        <div className="col-1 pt-2">&nbsp;</div>
                        <div className="col-2 pt-2">
                          <label className="form-label">Coach</label>
                        </div>
                        <div className="col-8">
                          <select
                            className="form-control"
                            name="coach"
                            id="coach"
                            ref={coachListRef}
                            placeholder="Coach ..."
                            onChange={handleSelectCoach}
                          >
                            {Object.keys(coach).length === 0 && (
                              <option
                                value=""
                                selected
                                disabled
                                hidden
                              ></option>
                            )}
                            {listeCoaches.map((item) => (
                              <option
                                value={item.id}
                                key={item.id}
                                selected={
                                  coach?.id === item.id ? "selected" : ""
                                }
                              >
                                {item.firstname} {item.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                {Object.keys(coach).length !== 0 && (
                  <div className="row mt-1 mb-3">
                    <div className="col-1">&nbsp;</div>
                    <div className="col-5 ml-2">
                      NC déja attribués : {coach.total_suivi}
                    </div>
                    <div className="col-5">
                      NC restant : {totalByCoach - coach.total_suivi}
                    </div>
                  </div>
                )}
              </div>
              <div className="row mt-2 mb-3">
                <div className="col-12">
                  <table className="table table-striped table-bordered text-center table-sm">
                    <thead>
                      <tr>
                        <th>&nbsp;</th>
                        <th>
                          <small>Prénom</small>
                        </th>
                        <th>
                          <small>Nom</small>
                        </th>
                        <th>
                          <small>Email</small>
                        </th>
                        <th>
                          <small>Date Inscription</small>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {contacts.map(
                        (contact, index) =>
                          contact && (
                            <tr key={contact.id}>
                              <th scope="row">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value={contact.id}
                                    id="flexCheckDefault"
                                    onChange={(e) =>
                                      handleChangeCheck(contact, e)
                                    }
                                    defaultChecked={contact?.checked}
                                  />
                                </div>
                              </th>
                              <td>
                                <small>{contact.firstname}</small>
                              </td>
                              <td>
                                <small>{contact.lastname}</small>
                              </td>
                              <td>
                                <small>{contact.email}</small>
                              </td>
                              <td>
                                <small>
                                  {moment(contact.subscription_date).format(
                                    "DD/MM/yyyy"
                                  )}
                                </small>
                              </td>
                            </tr>
                          )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </DialogContentText>
          )}
          {isNext && (
            <div>
              <div className="sticky-top header-prog31">
                <div className="row mt-0 ml-0 mr-0 pt-2">
                  <div className="col-2 pt-2">
                    <label className="form-label">RECAPITULATIF</label>
                  </div>
                </div>
                <div className="row mt-0 ml-0 mr-0 mb-3">
                  <div className="col-12 pt-2">
                    Coach : {coach.firstname} {coach.name}
                  </div>
                </div>
              </div>
              <div className="row mt-2 mb-3">
                <div className="col-12">
                  <table className="table table-striped table-bordered text-center table-sm">
                    <thead>
                      <tr>
                        <th>Prénom</th>
                        <th>Nom</th>
                        <th>Email</th>
                        <th>Date Inscription</th>
                        <th>Statut</th>
                      </tr>
                    </thead>
                    <tbody>
                      {listeAttributions.map(
                        (attribution, index) =>
                          attribution && (
                            <tr key={attribution.id}>
                              <td>{attribution.firstname}</td>
                              <td>{attribution.lastname}</td>
                              <td>{attribution.email}</td>
                              <td>
                                {moment(attribution.subscription_date).format(
                                  "DD/MM/yyyy"
                                )}
                              </td>
                              <td>{attribution.status}</td>
                            </tr>
                          )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row mt-2 mb-3">
                <div className="col-auto">
                  <Button
                    variant="outlined"
                    color="success"
                    onClick={handleAffectationClick}
                    autoFocus
                  >
                    Enregistrer
                  </Button>
                </div>
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          {isNext && (
            <DialogContentText id="alert-dialog-description" component={"div"}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleGoPrevious}
                autoFocus
              >
                Précédent
              </Button>
            </DialogContentText>
          )}
          {!isNext && (
            <Button
              variant="outlined"
              color="primary"
              onClick={handleGoNext}
              autoFocus
            >
              Suivant
            </Button>
          )}
          <Button
            onClick={handleCloseDialog}
            variant="outlined"
            color="error"
            className="ml-2"
          >
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
    </div>
  );
}
