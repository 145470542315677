import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import DataTablePerseverance from "../../components/layouts/data-table-perseverance";
import AddPerseverance from "./add-perseverance";
import SuiviTelephoniqueService from "../../services/suivi-telephonique-service";

const ListPerseverance = () => {
  const initialState = [];
  const [contacts, setContacts] = useState(initialState);
  const [statuts, setStatut] = useState(initialState);
  const [doUpdate, setDoUpdate] = useState(true);

  useEffect(() => {
    if (doUpdate) {
      SuiviTelephoniqueService.getListePerseverance().then((datas) => {
        const items = datas.data
          .filter((a) => a !== null)
          .map((a) => ({
            ...{
              id: a.id,
              contact: `${a.contact.lastname} ${a.contact.firstname}`,
              date_appel: a.date_appel,
              statut_id: a.statut.id,
              statut_appel: a.statut.libelle,
              commentaires: a.commentaires,
            },
          }));
        setContacts(items);
      });

      SuiviTelephoniqueService.getStatutAppels().then((response) => {
        setStatut(response.data);
      });
      setDoUpdate(false);
    }
  }, [doUpdate]);

  const sendUpdate = (value) => {
    setDoUpdate(value);
  };

  return (
    <>
      <div id="main-content">
        <div className="block-header">
          <div className="row clearfix">
            <div className="col-md-6 col-sm-12">
              <h4 className="text-31-secondary">Liste Persévérance</h4>
            </div>
            <div className="col-md-6 col-sm-12 text-right">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/home">
                    <i className="icon-home"></i>
                  </a>
                </li>
                <li className="breadcrumb-item active">Suivi Téléphonique</li>
                <li className="breadcrumb-item active">Liste Persévérance</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row clearfix">
            <div className="col-lg-12">
              <div className="card">
                <div className="body">
                  <div className="table-responsive">
                    <div className="text-right m-1 mb-2">
                      <AddPerseverance
                        statuts={statuts}
                        sendUpdate={sendUpdate}
                      />
                    </div>
                    <DataTablePerseverance
                      contacts={contacts}
                      statuts={statuts}
                      sendUpdate={sendUpdate}
                    />
                    <ToastContainer />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListPerseverance;
