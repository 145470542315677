import api from "./api";

const list = async () => {
  return await api.get("/v1/session-bilans").then((response) => response.data);
};

// const list = () => {
//   return http.get("/v1/session-bilans").then((response) => response.data);
// }
const add = async (user) => {
  return await api.post("/v1/session-bilans", user, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token').accessToken
    }
  }).then((response) => response);
};
const remove = async id => {
  return await api.delete(`/v1/session-bilans/${id}`)
              .then((response) => response);
};

const edit = async (id) => {
  return await api.get(`/v1/session-bilans/${id}`)
              .then((response) => response);
};
const update = async (id, dateIn) => {
  return await api.put(`/v1/session-bilans/${id}`, dateIn)
              .then((response) => response);
};

const BilanService = {
  list,
  add,
  edit,
  update,
  remove
};
export default BilanService;
