import Cookies from 'js-cookie';
import api from "./api";
import TokenService from "./token.service";

const login = async (email, password) => {
  return await api
    .post("/login", {
      email,
      password
    }, { withCredentials: true })
    .then(response => {
      if (response.data.accessToken) {
        TokenService.setUser(response.data);
        Cookies.set(response.data.accessToken)
      }
      return response.data;
    });
}

function logout() {
  TokenService.removeUser();
}

function getCurrentUser() {
  return TokenService.getUser();
}

const profile = () => {
  return api.get("/profile");
}

const checkToken = () => {
  const token = localStorage.getItem('token');
  if (token) {
    return true;
  } else {
    if (!token) {
      api.get("refresh/", { withCredentials: true }).then((res) => {
        TokenService.setUser(res.data);
        Cookies.set(res.data.access);
        console.log(res.data);
        return false;
      })
    }
  }
}

const AuthService = {
  login,
  logout,
  getCurrentUser,
  checkToken,
  profile
};
export default AuthService;
