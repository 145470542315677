import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGrid, GridToolbar, frFR } from "@mui/x-data-grid";
import { useDemoData } from "@mui/x-data-grid-generator";
import ContactService from "../../services/contact-service";
import DetailSession from "../../pages/gestionNc/detail-session";
import EditNc from "../../pages/gestionNc/edit-nc";
import { toast, ToastContainer } from "react-toastify";

const VISIBLE_FIELDS = [
  "id",
  "lastname",
  "firstname",
  "email",
  "telephone",
  "genre",
  "est_mineur",
  "birthdate",
  "author_id",
  "subscription_date",
  "created_at",
  "ville",
  "secteur_gf",
  "code_postal",
  "adresse_postale",
  "metier",
  "source",
  "source_id",
  "liste_id",
  "actions",
  "libelle_source",
];
const DataTablePrincipal = ({
  contacts,
  listes,
  secteurs,
  sources,
  sendUpdate,
}) => {
  const [pageSize, setPageSize] = React.useState(5);
  const roleId = parseInt(localStorage.getItem("roleId"));
  const columns = [
    {
      field: "id",
      sort: "desc",
      hide: true,
      headerName: "Id",
      flex: 1,
      headerClassName: "header-prog31",
    },
    {
      field: "lastname",
      headerName: "Nom",
      flex: 1,
      headerClassName: "header-prog31",
    },
    {
      field: "firstname",
      headerName: "Prenom",
      flex: 1,
      headerClassName: "header-prog31",
    },
    {
      field: "author",
      hide: true,
      headerName: "Saisi Par",
      flex: 1,
      headerClassName: "header-prog31",
    },
    {
      field: "subscription_date",
      hide: true,
      headerName: "Date de saisie",
      flex: 1,
      headerClassName: "header-prog31",
    },
    {
      field: "created_at",
      hide: true,
      headerName: "Créé le",
      flex: 1,
      headerClassName: "header-prog31",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      headerClassName: "header-prog31",
    },
    {
      field: "telephone",
      headerName: "Telephone",
      flex: 1,
      headerClassName: "header-prog31",
    },
    {
      field: "genre",
      hide: true,
      headerName: "Genre",
      flex: 1,
      hideable: true,
      headerClassName: "header-prog31",
    },
    {
      field: "etat_mineur",
      headerName: "Ado/Adulte",
      flex: 1,
      headerClassName: "header-prog31",
    },
    {
      field: "birthdate",
      hide: true,
      type: "",
      headerName: "Né le",
      flex: 1,
      headerClassName: "header-prog31",
    },

    {
      field: "metier",
      hide: true,
      type: "",
      headerName: "Métier",
      flex: 1,
      headerClassName: "header-prog31",
    },
    {
      field: "libelle_source",
      type: "",
      headerName: "Source",
      flex: 1,
      headerClassName: "header-prog31",
    },
    { field: "source_id", hide: true, headerClassName: "header-prog31" },
    { field: "ville", hide: true, headerClassName: "header-prog31" },
    { field: "code_postal", hide: true, headerClassName: "header-prog31" },
    { field: "secteur_gf", hide: true, headerClassName: "header-prog31" },
    { field: "adresse_postale", hide: true, headerClassName: "header-prog31" },
    { field: "liste_id", hide: true, headerClassName: "header-prog31" },
    {
      field: "bilan",
      headerName: "Bilan",
      minWidth: 20,
      headerClassName: "header-prog31",
      renderCell: (cellValues) => {
        return (
          <strong>
            <DetailSession cellValues={cellValues} sendUpdate={sendUpdate} />
          </strong>
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 200,
      headerClassName: "header-prog31",
      renderCell: (id) => (
        <>
          <EditNc
            nc={id.row}
            id={id}
            listes={listes}
            sources={sources}
            secteurs={secteurs}
            sendUpdate={sendUpdate}
          />
          {roleId === 1 && (
            <Button
              id="deleteBtn"
              style={{
                backgroundColor: "#e8605d",
                color: "#fff",
                padding: "7px 7px",
              }}
              onClick={() => {
                if (
                  window.confirm(
                    "Êtes-vous sûr de vouloir supprimer cet enregistrement ?"
                  )
                ) {
                  handleDelete(id);
                }
              }}
              variant="contained"
              color="primary"
              type="submit"
            >
              <DeleteIcon />
            </Button>
          )}
        </>
      ),
    },
  ];

  const { data } = useDemoData({
    dataSet: "Commodity",
    rowLength: 100,
    maxColumns: 6,
    visibleFields: VISIBLE_FIELDS,
  });
  // const navigate = useNavigate();

  const handleDelete = (contact) => {
    ContactService.remove(contact.id)
      .then((data) => {
        toast.success("Contact supprimé !", { autoClose: 2000 });
        sendUpdate(true);
        // navigate("/list-nc");
      })
      .catch((e) => {
        toast.error(e.response.data.description, {
          autoClose: 3000,
          onClose: () => {}, // TODO, mise à jour des écrans
        });
      });
  };
  return (
    <div style={{ height: 450, width: "100%" }}>
      <DataGrid
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        {...data}
        initialState={{
          sorting: {
            sortModel: [{ field: "id", sort: "desc" }],
          },
        }}
        components={{ Toolbar: GridToolbar }}
        rows={contacts}
        columns={columns}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20]}
        pagination
      />
      <ToastContainer />
    </div>
  );
};

export default DataTablePrincipal;
