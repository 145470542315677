import React, { useState, useRef } from "react";
import ContactService from "../../services/contact-service";
import BilanService from "../../services/bilan-service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Pointage = () => {
  const [key, setKey] = useState("");
  const [contacts, setContacts] = useState([]);
  const [data, setData] = useState([]);
  const [msg, setMsg] = useState("");
  const bilanId = useRef();
  const dateBilan = useRef();

  const handleClick = (e) => {
    e.preventDefault();
    ContactService.search(key)
      .then((response) => {
        if (response.data.data.length > 0) {
          setContacts(response.data.data);
        } else {
          setMsg("Pas de nc trouvé");
        }
      })
      .catch((err) => setMsg("Pas de nc trouvé"));
  };

  const handleChange = (contact, isChecked) => {
    if (isChecked) {
      contact.status = "En attente";
      setData((prevData) => [...prevData, contact]);
    } else {
      setData((prevData) => prevData.filter((c) => c.id !== contact.id));
    }
  };

  const handlePointage = (e) => {
    e.preventDefault();
    if (parseInt(bilanId.current.value) === 0) {
      toast.error("Vous devez selectionner une semaine !");
      return;
    }
    data.forEach((d) => {
      BilanService.add({
        contact_id: parseInt(d.contact_session_bilans[0].contact_id),
        bilan_id: parseInt(bilanId.current.value), // CNR parseInt(d.contact_session_bilans[0].bilan_id),
        date: dateBilan.current.valueAsDate.toISOString().slice(0, 10), // CNR todayDate,
      })
        .then((response) => {
          d.status = "Succès";
          setData((prevData) => [...prevData.filter((c) => c.id !== d.id), d]);
          toast.success(response.data.description, {
            autoClose: 2000,
            onClose: () => {
              // window.location.reload();
            },
          });
        })
        .catch((error) => {
          d.status = "Echec";
          setData((prevData) => [...prevData.filter((c) => c.id !== d.id), d]);
          toast.error(error.response.data.description);
        });
    });
  };

  return (
    <div id="main-content">
      <div className="block-header">
        <div className="row clearfix">
          <div className="col-md-6 col-sm-12">
            <h4 className="text-31-secondary">Pointage bilan des NC</h4>
          </div>
          <div className="col-md-6 col-sm-12 text-right">
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="index.html">
                  <i className="icon-home"></i>
                </a>
              </li>
              <li className="breadcrumb-item">Gestion des NC</li>
              <li className="breadcrumb-item active">Pointage</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row clearfix">
          <div className="col-lg-12">
            <div className="card">
              <div className="body">
                <div>
                  <div className="row mb-3">
                    <div className="input-group col-3">
                      <input
                        type="search"
                        className="form-control"
                        placeholder="Rechercher ..."
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        onChange={(e) => {
                          setKey(e.target.value);
                        }}
                      />
                      <button
                        className="btn btn-secondary"
                        onClick={handleClick}
                      >
                        <span
                          className=" fa fa-search"
                          id="basic-addon1"
                        ></span>
                      </button>
                    </div>
                    <div className="col-3">&nbsp;</div>
                    <div className="input-group col-2">
                      <select
                        className="form-control form-select"
                        aria-label="Default select example"
                        ref={bilanId}
                      >
                        <option value="0">Choisir semaine</option>
                        <option value="1">Semaine 1</option>
                        <option value="2">Semaine 2</option>
                        <option value="3">Semaine 3</option>
                        <option value="4">Semaine 4</option>
                        <option value="5">Semaine 5</option>
                      </select>
                    </div>
                    <div className="col-3">
                      <div className="form-inline offset-2">
                        <label htmlFor="date_nc" className="mr-2">
                          Date à ajouter:{" "}
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          name="date_nc"
                          id="date_nc"
                          ref={dateBilan}
                          value={new Date().toISOString().split("T")[0]}
                          max={new Date().toISOString().split("T")[0]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="row">
                        <div
                          className="mt-2 col-12"
                          style={{ borderRight: "2px solid black" }}
                        >
                          {contacts && contacts.length !== 0 ? (
                            <div>
                              <span className="text-31-primary">
                                <strong>
                                  Résultat(s) à votre recherche:{" "}
                                  {contacts.length} NCs
                                </strong>
                              </span>
                              <form>
                                <ul className="list-group list-group-horizontal">
                                  <li className="list-group-item col">
                                    <strong>Prénom</strong>
                                  </li>
                                  <li className="list-group-item col">
                                    <strong>Nom</strong>
                                  </li>
                                  <li className="list-group-item col">
                                    <strong>Téléphone</strong>
                                  </li>
                                  <li className="list-group-item col">
                                    &nbsp;
                                  </li>
                                </ul>
                                {contacts.map((contact) => (
                                  <ul
                                    className="list-group list-group-horizontal"
                                    key={contact.id}
                                  >
                                    <li className="list-group-item col">
                                      {contact.firstname}
                                    </li>
                                    <li className="list-group-item col">
                                      {contact.lastname}
                                    </li>
                                    <li className="list-group-item col">
                                      {contact.telephone}
                                    </li>
                                    <li className="list-group-item col text-center">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value={contact.id}
                                        onChange={(e) =>
                                          handleChange(
                                            contact,
                                            e.target.checked
                                          )
                                        }
                                      />
                                    </li>
                                  </ul>
                                ))}
                              </form>
                            </div>
                          ) : (
                            <strong className="text-danger text-center">
                              {msg}
                            </strong>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      {data.length > 0 && (
                        <div className="row">
                          <div className="mt-2 col-12">
                            <span className="text-center text-31-primary">
                              <strong>NC sélectionnés pour le pointage</strong>
                            </span>
                            <table className="table table-hover table-striped">
                              <tbody>
                                <tr>
                                  <th>Prénom</th>
                                  <th>Nom</th>
                                  <th>Téléphone</th>
                                  <th>Status</th>
                                </tr>
                                {data.map((contact) => (
                                  <tr key={contact.id}>
                                    <td>{contact.firstname}</td>
                                    <td>{contact.lastname}</td>
                                    <td>{contact.telephone}</td>
                                    <td>{contact.status}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <div className="text-center my-3 col-12">
                            <button
                              mt="5"
                              type="button"
                              className="btn btn-outline-primary btn-lg"
                              data-bs-toggle="modal"
                              data-bs-target="#myModal"
                              onClick={handlePointage}
                            >
                              Procéder au pointage
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <ToastContainer />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pointage;
