import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import AdresseForm from "./adresse-form";
import ContactService from "../../services/contact-service";
import { Button } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

const AddNc = (props) => {
  const initialState = {
    firstname: "",
    lastname: "",
    telephone: "",
    email: "",
    birthdate: "",
    est_mineur: 0,
    genre: "",
    secteur_gf: "",
    civilite: "",
    metier: "",
    liste_id: "",
    source_id: "",
    ville: "",
    code_postal: "",
    postalCode: "",
    adresse_postale: "",
    subscription_date: "",
  };
  const [nc, setNc] = useState(initialState);
  const [secteurs, setSecteurs] = useState([]);
  const [sources, setSources] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    ContactService.getSources().then((data) => {
      setSources(data.data);
    });
    ContactService.getSecteurs().then((data) =>
      setSecteurs(Object.values(data))
    );
  }, []);

  const handleChange = (event) => {
    event.preventDefault();
    const name = event.target.name;
    const value = event.target.value;
    let data = { ...nc, [name]: value };
    setNc(data);
  };
  const getForatDate = (nd) => {
    let d = new Date(nd);
    let df = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
    return df;
  };
  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleModalShow = () => {
    setShowModal(true);
  };

  const handleReinitial = (e) => {
    e.preventDefault();
    setNc(initialState);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newContacts = { ...nc };
    newContacts.birthdate = getForatDate(nc.birthdate);
    newContacts.subscription_date = getForatDate(nc.subscription_date);
    setNc(newContacts);
    ContactService.add(nc)
      .then((data) => {
        toast.success("Insertion réussie!");
        props.sendUpdate(true);
      })
      .catch((e) => {
        console.log(e);
        toast.error("Problème observé lors de l'insertion!");
      });
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleModalShow}
        className="m-1 text-white"
      >
        + Nouveau
      </Button>
      <Modal show={showModal} onHide={handleModalShow}>
        <Modal.Header closeButton className="close">
          <Modal.Title>
            <small>Créer un nouveau NC</small>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <div className="row">
              <div className="form-group col">
                <label htmlFor="civile">Civilité:</label>
                <select
                  className="form-control"
                  id="civile"
                  name="civilite"
                  value={nc.civilite}
                  onChange={handleChange}
                >
                  <option hidden>Choisir la civilité</option>
                  <option value="madame">Madame</option>
                  <option value="mademoiselle">Mademoiselle</option>
                  <option value="monsieur">Monsieur</option>
                </select>
              </div>
              <div className="form-group col">
                <label htmlFor="nom">Nom:*</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Entrer le nom"
                  id="lastname"
                  name="lastname"
                  required
                  value={nc.lastname}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col">
                <label htmlFor="prenom">Prénom:*</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Entrer le prénom"
                  id="prenom"
                  name="firstname"
                  required
                  value={nc.firstname}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <label htmlFor="email">Email adresse:</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Entrer l'email adresse"
                  id="email"
                  name="email"
                  value={nc.email}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col">
                <label htmlFor="tel">Téléphone*</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Entrer le téléphone"
                  required
                  id="tel"
                  name="telephone"
                  value={nc.telephone}
                  pattern="^\d{10}$"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <label htmlFor="est_mineur">Ado/Adulte:</label>
                <select
                  className="form-control"
                  id="est_mineur"
                  name="est_mineur"
                  value={nc.est_mineur}
                  onChange={handleChange}
                >
                  <option hidden>Choisir ...</option>
                  <option value="0">Ado</option>
                  <option value="1">Adulte</option>
                </select>
              </div>
              <div className="form-group col">
                <label htmlFor="date">Date de naissance</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Entrer le téléphone"
                  id="date"
                  name="birthdate"
                  value={nc.birthdate}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col">
                <label htmlFor="tel">Métier</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Entrer le métier"
                  id="metier"
                  name="metier"
                  value={nc.metier}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col">
                <label htmlFor="date">Date d'inscription*</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Entrer le téléphone"
                  required
                  id="subscription_date"
                  name="subscription_date"
                  value={nc.subscription_date}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col">
                <label htmlFor="tel">Secteur</label>
                <select
                  className="form-control"
                  id="secteur_gf"
                  name="secteur_gf"
                  value={nc.secteur_gf}
                  onChange={handleChange}
                >
                  <option value="">Choisir un secteur</option>
                  {Array.isArray(secteurs) &&
                    secteurs.map((s, index) => (
                      <option key={index} value={s}>
                        {s}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-4">
                <label htmlFor="ville">Ville:</label>
                <AdresseForm
                  setCodePostal={(codePostal, ville) =>
                    setNc({
                      ...nc,
                      code_postal: codePostal,
                      ville: ville,
                    })
                  }
                  handleReinitial={handleReinitial}
                />
              </div>
              <div className="form-group col-3">
                <label htmlFor="code_postal">Code Postal:</label>
                <input
                  className="form-control"
                  id="code_postal"
                  name="code_postal"
                  placeholder="Entre le code postal"
                  value={nc.code_postal}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-5">
                <label htmlFor="adresse_postale">Adresse:</label>
                <input
                  className="form-control"
                  id="adresse_postale"
                  name="adresse_postale"
                  placeholder="Adresse postale..."
                  value={nc.adresse_postale}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-4">
                <label htmlFor="source">Source*:</label>
                <select
                  className="form-control"
                  id="source_id"
                  name="source_id"
                  value={nc.source_id}
                  onChange={handleChange}
                  required
                >
                  <option hidden>Choisir ...</option>
                  {sources.map((s) => (
                    <option key={s.id} value={s.id}>
                      {s.libelle}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <ToastContainer />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            className="ml-1 mr-1"
          >
            Modifier
          </Button>
          <Button
            variant="contained"
            color="warning"
            onClick={handleReinitial}
            className="ml-1 mr-1"
          >
            Réinitialiser
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleModalClose}
            className="ml-1 mr-1"
          >
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddNc;
