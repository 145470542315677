//import http from "../http-common";
import api from "./api";

const list = async () => {
  return await api
    .get("/v1/mon-suivi-telephonique")
    .then((response) => response.data);
};

const getListePerseverance = async () => {
  return await api
    .get("/v1/suivi-telephonique-perseverance")
    .then((response) => response.data);
};

const getStatutAppels = async () => {
  return await api.get("/v1/statut-appels").then((response) => response.data);
};
const add = async (user) => {
  return await api
    .post("/v1/suivi-telephoniques", user)
    .then((response) => response);
};

const remove = async (id) => {
  return await api
    .delete(`/v1/suivi-telephoniques/${id}`)
    .then((response) => response);
};

const edit = async (id) => {
  return await api
    .get(`/v1/suivi-telephoniques/${id}`)
    .then((response) => response);
};
const update = async (id, contact) => {
  return await api
    .put(`/v1/suivi-telephoniques/${id}`, contact)
    .then((response) => response);
};

const listTypes = async () => {
  return await api
    .get("/v1/contact-list-types")
    .then((response) => response.data);
};

const getSources = async () => {
  return await api.get("/v1/source-contacts").then((response) => response.data);
};

const SuiviTelephoniqueService = {
  getStatutAppels,
  getListePerseverance,
  list,
  add,
  edit,
  update,
  remove,
  listTypes,
  getSources,
};
export default SuiviTelephoniqueService;
