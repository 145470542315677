import React from 'react'
import logo from './logo.svg';
import './App.css';
import { Header } from './components/layouts/header';
import 'react-toastify/dist/ReactToastify.css';
  
function App() {
  return (
    <div>
      <Header/>    
    </div>
  );
}

export default App;
