import React, { useState, useEffect } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import BilanService from "../../services/bilan-service";
import { toast, ToastContainer } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import { Modal, Button } from "react-bootstrap";
import InfoIcon from "@mui/icons-material/Info";
import UpdateSession from "../../pages/gestionNc/update-session";

const DetailSession = (props) => {
  const initialState = [];
  const initialBilan = {
    contact_id: props.cellValues.id,
    bilan_id: 0,
    date: "",
  };

  const [data, setData] = useState(initialState);
  const [add, setAdd] = useState(false);
  const [bilan, setBilan] = useState(initialBilan);
  const [show, setShow] = useState(false);

  useEffect(() => {
    const { bilans } = props.cellValues.row;
    setData(bilans);
  }, [props.cellValues.row]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCheckChange = (event) => {
    setAdd(event.target.checked);
  };

  const handleChange = (event) => {
    event.preventDefault();
    const name = event.target.name;
    const value = event.target.value;
    let data = { ...bilan, [name]: value };
    setBilan(data);
  };

  const handleDelete = (a) => {
    BilanService.remove(a.id).then((res) => {
      toast.success("Contact supprimé!", { autoClose: 2000 });
      props.sendUpdate(true);
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (bilan.date === "") {
      toast.error("Vous devez choisir une date d'inscription !");
      return;
    }
    if (bilan.bilan_id === 0) {
      toast.error("Vous devez choisir une session de bilan !");
      return;
    }
    BilanService.add(bilan)
      .then((data) => {
        toast.success("Insertion réussie!", {
          onClose: () => {},
        });
        props.sendUpdate(true);
      })
      .catch((err) => {
        toast.error(err.response.data.description);
      });
  };

  return (
    <>
      <Button variant="info" onClick={handleShow} className="text-white m-1">
        <InfoIcon />
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        id={`session${props.cellValues.id}`}
      >
        <Modal.Header closeButton>
          <Modal.Title>Bilan de sessions: </Modal.Title>
          <div className="text-right">
            <FormGroup className="text-right">
              <FormControlLabel
                control={
                  <Switch
                    className="text-right"
                    color="secondary"
                    checked={add}
                    onChange={handleCheckChange}
                  />
                }
                label="Lister / Ajouter"
              />
            </FormGroup>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-6 offset-2">
              <h5 className="text-31-secondary">
                {props.cellValues.row.civilite?.charAt(0).toUpperCase() +
                  props.cellValues.row.civilite?.slice(1)}{" "}
                {props.cellValues.row.firstname}
              </h5>
            </div>
          </div>
          {add ? (
            <div>
              <form onSubmit={handleSubmit}>
                <div className="mb-1">
                  <label htmlFor="semaines">Semaines:</label>
                  <select
                    id="semaines"
                    name="bilan_id"
                    onChange={handleChange}
                    className="form-control"
                  >
                    <option value={bilan.bilan_id} hidden>
                      Choisir
                    </option>
                    <option value={1}>Bilan semaine 1</option>
                    <option value={2}>Bilan semaine 2</option>
                    <option value={3}>Bilan semaine 3</option>
                    <option value={4}>Bilan semaine 4</option>
                    <option value={5}>Bilan semaine 5</option>
                  </select>
                </div>
                <div className="mb-1">
                  <label htmlFor="date">Date de session:</label>
                  <input
                    type="date"
                    id="date"
                    onChange={handleChange}
                    name="date"
                    className="form-control"
                    max={new Date().toISOString().split("T")[0]}
                  />
                </div>
                <div>
                  <button type="submit" className="btn btn-secondary col-12">
                    Ajouter
                  </button>
                </div>
              </form>
              <ToastContainer />
            </div>
          ) : (
            <ul className="list-group list-group-flush">
              {data.map((a, index) => {
                return (
                  <li key={index} className="list-group-item">
                    <span style={{ marginRight: "20px" }}>
                      Semaine {a.bilan_id}: Effectué le:{" "}
                      {a.date.split("-").reverse().join("-")}
                    </span>
                    <UpdateSession
                      id_session={a.id}
                      a_bilan={a}
                      semaine={a.date.split("-").reverse().join("-")}
                      sendUpdate={props.sendUpdate}
                    />
                    <Button
                      variant="danger"
                      onClick={(e) => {
                        e.preventDefault();
                        if (
                          window.confirm(
                            "Êtes-vous sûr de vouloir supprimer cet enregistrement ?"
                          )
                        ) {
                          handleDelete(a);
                        }
                      }}
                    >
                      <DeleteIcon
                        color="white"
                        className="text-end ml-1 mr-1"
                      />
                    </Button>
                  </li>
                );
              })}
            </ul>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DetailSession;
