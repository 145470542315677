import React, { useState } from "react";
import axios from "axios";
import ContactService from "../../services/contact-service";

const AdresseForm = ({ setCodePostal, handleReinitial }) => {
  const [ville, setVille] = useState("");
  const handleVilleChange = (e) => {
    const ville = e.target.value;
    setVille(ville);
    handleReinitial = (e) => setVille(null);
    // Effectuez une requête de géocodage vers l'API Nominatim d'OpenStreetMap pour obtenir les détails de l'adresse
    axios
      .get(
        `https://nominatim.openstreetmap.org/search?q=${ville}&format=json&addressdetails=1&limit=1`
      )
      .then((response) => {
        const data = response.data;
        if (data.length > 0) {
          // Trouvez le code postal dans les résultats de géocodage api/v1/contact-secteurs-gf
          const addressDetails = data[0].address;
          const codePostal =
            addressDetails.postcode || "Code postal non disponible";
          setCodePostal(codePostal, ville);

          ContactService.searchSecteur(codePostal).then((res) =>
            console.log(res)
          );
        } else {
          // Si aucun résultat n'est trouvé, réinitialisez le champ "code postal"
          setCodePostal("", "");
        }
      })
      .catch((error) => {
        // Gestion des erreurs
        console.error("Erreur lors de la requête API Nominatim :", error);
        setCodePostal("");
      });
  };

  return (
    <div>
      <input
        className="form-control"
        type="text"
        placeholder="Entrez le nom de la ville"
        value={ville}
        onChange={handleVilleChange}
      />
    </div>
  );
};

export default AdresseForm;
