import api from "./api";

const list = async () => {
  return await api.get("/v1/contacts").then((response) => response.data);
};
const getContactAttenteAttr = async () => {
  return await api
    .get("/v1/contact-attente-attrib")
    .then((response) => response.data);
};
const add = async (user) => {
  return await api.post("/v1/contacts", user).then((response) => response);
};
const remove = async (id) => {
  return await api.delete(`/v1/contacts/${id}`).then((response) => response);
};

const edit = async (id) => {
  return await api.get(`/v1/contacts/${id}`).then((response) => response);
};
const update = async (id, user) => {
  return await api
    .put(`/v1/contacts/${id}`, user)
    .then((response) => console.log(response));
};

const getListePrincipale = async () => {
  return await api
    .get("/v1/contact-principale")
    .then((response) => response.data);
};

const getListePerseverance = async() => {
  return await api.get("/v1/contact-perseverance").then((response) => response.data);
};

const getMesSaisies = async() => {
  return await api.get("/v1/contact-mes-saisies").then((response) => response.data);
};

const search = async (pattern) => {
  return await api
    .post("/v1/contact-find", { pattern: pattern })
    .then((response) => response);
};

const searchSecteur = async (pattern) => {
  return await api
    .post("/v1/contact-secteurs-gf/" + pattern)
    .then((response) => response);
};

const listTypes = async () => {
  return await api
    .get("/v1/contact-list-types")
    .then((response) => response.data);
};

const getSecteurs = async () => {
  return await api
    .get("/v1/contact-secteurs-gf")
    .then((response) => response.data);
};

const getSources = async () => {
  return await api.get("/v1/source-contacts").then((response) => response.data);
};

const ContactService = {
  list,
  getContactAttenteAttr,
  add,
  edit,
  search,
  searchSecteur,
  update,
  remove,
  listTypes,
  getSources,
  getSecteurs,
  getListePrincipale,
  getListePerseverance,
  getMesSaisies,
};
export default ContactService;
