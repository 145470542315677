import { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Button } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Edit } from "@mui/icons-material";
import SuiviTelephoniqueService from "../../services/suivi-telephonique-service";
import moment from "moment";

function EditSuiviTelephonique(props) {
  const initialState = {
    contact_id: "",
    contact: "",
    coach_appel: "",
    date_appel: "",
    statut_id: "",
    commentaires: "",
  };
  const [appels, setAppels] = useState(initialState);
  const [statuts, setStatut] = useState([]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setAppels(props.nc);
    setStatut(props.statuts);
  }, [props.nc, props.statuts]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setAppels((appels) => ({ ...appels, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const updateContact = { ...appels };
    updateContact.date_appel = moment(appels.date_appel).format("yyyy-MM-DD");
    updateContact.statut_id = appels.statut_id;
    updateContact.coach_id = JSON.parse(localStorage.getItem("userId"));
    setAppels(updateContact);
    SuiviTelephoniqueService.update(props.nc.id, appels)
      .then((data) => {
        toast.success("Modification réussie!");
        props.sendUpdate(true);
      })
      .catch((e) => {
        console.log(e);
        toast.error("Problème observé lors de la modification!");
      });
  };
  return (
    <>
      <Button
        variant="contained"
        color="warning"
        onClick={handleShow}
        className="m-1 text-white"
      >
        <Edit />
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit d'appel ...</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formContact">
              <Form.Label>Contact</Form.Label>
              <Form.Control
                type="text"
                name="contact"
                value={appels.contact}
                onChange={handleChange}
                readOnly
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Date d'appel</Form.Label>
              <Form.Control
                type="date"
                name="date_appel"
                value={appels.date_appel}
                onChange={handleChange}
                placeholder="Entrez la date d'appel"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Statut d'appel</Form.Label>
              <Form.Select
                className="form-control"
                name="statut_id"
                value={appels?.statut_id}
                onChange={handleChange}
              >
                {Array.isArray(statuts) &&
                  statuts.map((s) => (
                    <option key={s.id} value={s.id}>
                      {s.libelle}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Observations</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="commentaires"
                value={appels?.commentaires}
                onChange={handleChange}
                placeholder="Entrez votre commentaire"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            className="ml-1 mr-1"
          >
            Modifier
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleClose}
            className="ml-1 mr-1"
          >
            Fermer
          </Button>
        </Modal.Footer>
        <ToastContainer />
      </Modal>
    </>
  );
}

export default EditSuiviTelephonique;
