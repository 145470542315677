import React, { Component } from "react";
import { useLocation } from "react-router-dom";
import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import ProgramCertif from "./program-certif";
import CertificatService from "../../services/certificat-service";

// Create styles
const styles = StyleSheet.create({
  "@page": {
    margin: "10px 0px 0px 0px !important",
    padding: "0px 0px 0px 0px !important",
    size: "A4",
  },
  body: {
    fontFamily: 'Verdana, "Times New Roman", Times, serif',
  },
});

const Certificat2 = (props) => {
  const location = useLocation();
  console.log(location);
  const properties = { header: "Acme" };
  const handleUpdate = () => {
    let usersId = location.state.users.map((user) => user.id);
    CertificatService.updates([usersId]);
    console.log(usersId);
  };
  const MyDoc = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        {location.state.users.map((user) => (
          <View key={user.id}>
            <Image
              src="./assets/images/diplome-31-jours.png"
              className="img-fluid"
              alt=""
            />
            <Text
              className="texte_titre_1"
              style={{ position: "absolute", bottom: "180px", left: "220px" }}
            >
              {" "}
              {user.firstname} {user.lastname.toUpperCase()}{" "}
            </Text>
            <Text
              className="texte_lieu_date_1"
              style={{ position: "absolute", bottom: "57px", left: "80px" }}
            >
              Le Blanc-Mesnil, le{" "}
              {new Date(Date.now()).toLocaleString().slice(0, 3).match(/[0-9]/i)
                ? new Date(Date.now())
                    .toLocaleString()
                    .split(" ")[0]
                    .split(",")[0]
                : new Date(Date.now()).toLocaleString().split(" ")[1] +
                  " " +
                  new Date(Date.now()).toLocaleString().split(" ")[2] +
                  " " +
                  new Date(Date.now()).toLocaleString().split(" ")[3]}
            </Text>
          </View>
        ))}
      </Page>
    </Document>
  );

  return (
    <div id="main-content">
      <div className="block-header">
        <div className="row clearfix">
        <div className="col-md-6 col-sm-12">
              <h4 className="text-31-secondary">Gestion des Certificats</h4>
            </div>
          <div className="col-md-6 col-sm-12 text-right">
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="index.html">
                  <i className="icon-home"></i>
                </a>
              </li>
              <li className="breadcrumb-item">Gestion des Certificats</li>
              <li className="breadcrumb-item active">Certificats</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row clearfix">
          <div className="col-lg-12">
            <div className="card">
              <div className="body">
                <div className="table-responsive">
                  <div className="row ml-0 mb-2">
                    <PDFDownloadLink
                      document={<MyDoc />}
                      fileName="certificat.pdf"
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          "Loading document..."
                        ) : (
                          <i
                            className="fas fa-download btn btn-primary"
                            onClick={handleUpdate}
                          ></i>
                        )
                      }
                    </PDFDownloadLink>
                  </div>
                  {location.state.users.map((user, index) => (
                    <ProgramCertif key={index} user={user} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Certificat2;
