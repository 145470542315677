import { useState } from "react";
import { Button } from "@mui/material";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import EditIcon from "@mui/icons-material/Edit";
import { toast, ToastContainer } from "react-toastify";
import BilanService from "../../services/bilan-service";

function UpdateSession(props) {
  const initialState = {
    id: props.a_bilan.bilan_id,
    bilan_session: props.id_session,
    dateSem: props.a_bilan.date,
  };
  const [show, setShow] = useState(false);
  const [semaine, setSemaine] = useState(initialState);

  const handleChange = (event) => {
    event.preventDefault();
    const name = event.target.name;
    const value = event.target.value;
    let data = { ...semaine, [name]: value };
    setSemaine(data);
  };
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const handleClick = (e) => {
    e.preventDefault();
    let today = new Date(semaine.dateSem);
    today =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1 < 10 ? "0" : "") +
      (today.getMonth() + 1) +
      "-" +
      ((today.getDate() < 10 ? "0" : "") + today.getDate());

    BilanService.update(semaine.bilan_session, { date: today })
      .then((data) => {
        toast.success("Modification réussie!");
        props.sendUpdate(true);
      })
      .catch((e) => {
        toast.error("Problème observé lors de la modification!");
        console.log(e);
      });
    return;
  };

  return (
    <>
      <Button
        variant="contained"
        color="warning"
        onClick={handleShow}
        className="ml-1 mr-1"
      >
        <EditIcon />
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modification de la semaine</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label htmlFor="semaine">Semaine:</Form.Label>
          <Form.Control
            type="text"
            aria-label="Disabled input example"
            id="semaine"
            name={semaine.id}
            onChange={handleChange}
            className="form-control"
            value={`Bilan semaine ${semaine.id}`}
            disabled
            readOnly
          />
          <div className="mb-1">
            <label htmlFor="date">Date de session:</label>
            <input
              type="date"
              id="dateSem"
              name="dateSem"
              value={semaine.dateSem}
              onChange={handleChange}
              className="form-control"
              max={new Date().toISOString().split("T")[0]}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="contained"
            color="error"
            onClick={() => {handleClose();}}
            className="ml-1 mr-1"
          >
            Fermer
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => {handleClick(e);}}
            className="ml-1 mr-1"
          >
            Modifier
          </Button>
        </Modal.Footer>
        <ToastContainer />
      </Modal>
    </>
  );
}

export default UpdateSession;
