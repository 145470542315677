import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import UserService from "../../services/user-service";
import { ToastContainer, toast } from 'react-toastify';

const EditUser = (props) => {
  const initialState = {
    name: props?.data?.name,
    email: props?.data?.email,
    password: props?.data?.password,
    roles: props?.data?.roles.map((role) => [role.split('-')].map(u =>({id: u[0], title: u[1]})))[0],
    firstname: props?.data?.firstname,
    mobile_phone: props?.data?.mobile_phone,
    genre: props?.data?.genre,
    is_active: props?.data?.is_active,
    civilite: props?.data?.civilite,
    civil_status: props?.data?.civil_status,
  };

  const [user, setUser] = useState(initialState);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    console.log(props.data);
    let userClone = { ...user };
    for(let i = 0; i < userClone.roles.length; i++) {
      if(userClone.roles[i].id === "1"){
        userClone.etat1 = true;  
        //console.log(user);
        setUser(userClone);    
      }else if(userClone.roles[i].id === "2"){
        userClone.etat2 = true;  
        setUser(userClone);    
      }else if(userClone.roles[i].id === "3"){
        userClone.etat3 = true;   
        setUser(userClone);   
      }
    }
 
  }, []);
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    let data = { ...user, [name]: value };
    data.roles = [data.roles];
    delete data.etat1;
    delete data.etat2;
    delete data.etat3;
    console.log(data)
    setUser(data);
    console.log(user);
  };
  const handleUpdateSubmit = (event) => {
    event.preventDefault();
    console.log(user);
    UserService.update(props.id.id, user).then((data) => console.log(data));
    toast.success("Modification réussie!");
  };
  
  return (
    <>
     <Button variant="warning" onClick={handleShow}>
        <i className="fa fa-edit"></i>
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modification</Modal.Title>
        </Modal.Header>
       
        <form onSubmit={handleUpdateSubmit}>
        <Modal.Body>
                <div className="row">
                  <div className="form-group col">
                    <label htmlFor="civilite">Civilité:</label>
                    <select
                      className="form-control"
                      id={`civilite${props.id.id}`}
                      name="civilite"
                      value={user.civilite}
                      onChange={handleChange}
                    >
                      <option hidden>{user.civilite}</option>
                      <option value="madame">Madame</option>
                      <option value="mademoiselle">Mademoiselle</option>
                      <option value="monsieur">Monsieur</option>
                    </select>
                  </div>
                  <div className="form-group col">
                    <label htmlFor="statut">Statut:</label>
                    <select
                      className="form-control"
                      id={`statut${props.id.id}`}
                      name={`civil_status ${props.id.id}`}
                      value={user.civil_status}
                      onChange={handleChange}
                    >
                      <option hidden>{user.civil_status}</option>
                      <option value="célibataire">Célibataire</option>
                      <option value="marié(e)">Marié(e)</option>
                      <option value="veuf(ve)">Veuf(ve)</option>
                      <option value="divorcé(e)">Divorcé(e)</option>
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col">
                    <label htmlFor="name">Nom*:</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Entrer le nom"
                      id={`name ${props.id.id}`}
                      name="name"
                      value={user.name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col">
                    <label htmlFor="firstname">Prénom:*</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Entrer le prénom"
                      id={`firstname ${props.id.id}`}
                      name="firstname"
                      value={user.firstname}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col">
                    <label htmlFor="emailEdit">Email adresse:*</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Entrer l'email adresse"
                      id={`emailEdit ${props.id.id}`}
                      name="email"
                      value={user.email}
                      onChange={handleChange}
                      readOnly={true}
                    />
                  </div>
                  <div className="form-group col">
                    <label htmlFor="passwordEdit">Mot de passe:*</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Entrer le mot de passe"
                      id={`passwordEdit ${props.id.id}`}
                      name="password"
                      value={user.password}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col">
                    <label htmlFor="genreEdit">Genre:</label>
                    <select
                      className="form-control"
                      id={`genre${props.id.id}`}
                      name="genre"
                      value={user.genre}
                      onChange={handleChange}
                    >
                      <option hidden>{user?.genre}</option>
                      <option value="feminin">Feminin</option>
                      <option value="masculin">Masculin</option>
                    </select>
                  </div>
                  <div className="form-group col">
                    <label htmlFor="telEdit">Téléphone</label>
                    <input
                      type="tel"
                      className="form-control"
                      placeholder="Entrer le téléphone"
                      id={`telEdit ${props.id.id}`}
                      name="mobile_phone"
                      value={user.mobile_phone}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-group col">
                  <h6>Rôles</h6>
                  <div className="fancy-checkbox">
                    <label className="fancy-checkbox">
                      <input
                        onChange={handleChange}
                        name="roles"
                        type="checkbox"
                        value={1}
                        defaultChecked={user.etat1}
                      />
                      <span>Admin Système</span>
                    </label>
                
                    <label className="fancy-checkbox">
                      <input
                        onChange={handleChange}
                        name="roles"
                        type="checkbox"
                        value={2}
                        defaultChecked={user.etat2}
                      />
                      <span>Utilisateur</span>
                    </label>

                    <label className="fancy-checkbox">
                      <input
                        onChange={handleChange}
                        name="roles"
                        type="checkbox"
                        value={3}
                        defaultChecked={user.etat3}
                      />
                      <span>Apprenti</span>
                    </label>
                  </div>
                </div>
                <div className="form-group col">
                  <label className="fancy-checkbox">
                    <input
                      onChange={handleChange}
                      name="is_active"
                      type="checkbox"
                      value={user.is_active}
                      defaultChecked={user.is_active}
                    />
                    <span>Activé</span>
                  </label>
                </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button className="col-3" variant="secondary" onClick={handleClose}>
                    Fermer
                  </Button>
                  <Button className="col-8" type="submit" variant="primary" onClick={handleClose}>
                    Modifier
                  </Button>
                  <ToastContainer/>
                </Modal.Footer>
              </form>
        
      </Modal>
    </>
  );
};
export default EditUser;
