import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { NavLink, useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";

const Nav = (props) => {
  const [name, setName] = useState("");
  const [show, setShow] = useState(false);
  const history = useNavigate();
  useEffect(() => {
    let token = localStorage.getItem("token");
    let user = jwt_decode(token);
    setName(user.given_name);
    if (!token) {
      localStorage.removeItem("token");
      history("/login");
      window.location.reload();
    }
  }, []);

  const handlClick = () => {};

  const handleLoad = () => {
    window.location.reload();
  };
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };
  const handlelogout = () => {
    localStorage.removeItem("token");
    history("/login");
    setShow(false);
  };

  return (
    <>
      <div className="page-loader-wrapper">
        <div className="loader">
          <div className="m-t-30">
            <img
              src="../assets/images/icon-light.svg"
              width="48"
              height="48"
              alt="31 Jours"
            />
          </div>
          <p>Please wait...</p>
        </div>
      </div>

      <div className="overlay"></div>

      <div id="wrapper">
        <nav className="navbar navbar-fixed-top">
          <div className="container-fluid">
            <div className="navbar-left">
              <div className="navbar-btn">
                <NavLink to="/home">
                  <img
                    src="../assets/images/icon-light.svg"
                    alt="31 jours Logo"
                    className="img-fluid logo"
                  />
                </NavLink>
                <button type="button" className="btn-toggle-offcanvas">
                  <i className="lnr lnr-menu fa fa-bars"></i>
                </button>
              </div>
            </div>

            <div className="navbar-right">
              <div id="navbar-menu">
                <ul className="nav navbar-nav">
                  <li className="dropdown dropdown-animated scale-left">
                    <NavLink
                      to="#"
                      className="dropdown-toggle icon-menu"
                      data-toggle="dropdown"
                    >
                      <i className="icon-envelope"></i>
                      <span className="notification-dot"></span>
                    </NavLink>
                    <ul className="dropdown-menu right_chat email">
                      <li>
                        <NavLink to="#">
                          <div className="media">
                            <img
                              className="media-object "
                              src="../assets/images/xs/avatar4.jpg"
                              alt=""
                            />
                            <div className="media-body">
                              <span className="name">
                                James Wert{" "}
                                <small className="float-right">Just now</small>
                              </span>
                              <span className="message">
                                Lorem ipsum Veniam aliquip culpa laboris minim
                                tempor
                              </span>
                            </div>
                          </div>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="#">
                          <div className="media">
                            <img
                              className="media-object "
                              src="../assets/images/xs/avatar1.jpg"
                              alt=""
                            />
                            <div className="media-body">
                              <span className="name">
                                Folisise Chosielie{" "}
                                <small className="float-right">12min ago</small>
                              </span>
                              <span className="message">
                                There are many variations of Lorem Ipsum
                                available, but the majority
                              </span>
                            </div>
                          </div>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="#">
                          <div className="media">
                            <img
                              className="media-object "
                              src="../assets/images/xs/avatar5.jpg"
                              alt=""
                            />
                            <div className="media-body">
                              <span className="name">
                                Ava Alexander{" "}
                                <small className="float-right">38min ago</small>
                              </span>
                              <span className="message">
                                Many desktop publishing packages and web page
                                editors
                              </span>
                            </div>
                          </div>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="#">
                          <div className="media mb-0">
                            <img
                              className="media-object "
                              src="../assets/images/xs/avatar2.jpg"
                              alt=""
                            />
                            <div className="media-body">
                              <span className="name">
                                Debra Stewart{" "}
                                <small className="float-right">2hr ago</small>
                              </span>
                              <span className="message">
                                Contrary to popular belief, Lorem Ipsum is not
                                simply random text
                              </span>
                            </div>
                          </div>
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li className="dropdown dropdown-animated scale-left">
                    <NavLink
                      to="#"
                      className="dropdown-toggle icon-menu"
                      data-toggle="dropdown"
                    >
                      <i className="icon-bell"></i>
                      <span className="notification-dot"></span>
                    </NavLink>
                    <ul className="dropdown-menu feeds_widget">
                      <li className="header">You have 5 new Notifications</li>
                      <li>
                        <NavLink to="#">
                          <div className="feeds-left">
                            <i className="fa fa-thumbs-o-up text-success"></i>
                          </div>
                          <div className="feeds-body">
                            <h4 className="title text-success">
                              7 New Feedback{" "}
                              <small className="float-right text-muted">
                                Today
                              </small>
                            </h4>
                            <small>
                              It will give a smart finishing to your site
                            </small>
                          </div>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="#">
                          <div className="feeds-left">
                            <i className="fa fa-user"></i>
                          </div>
                          <div className="feeds-body">
                            <h4 className="title">
                              New User{" "}
                              <small className="float-right text-muted">
                                10:45
                              </small>
                            </h4>
                            <small>I feel great! Thanks team</small>
                          </div>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="#">
                          <div className="feeds-left">
                            <i className="fa fa-question-circle text-warning"></i>
                          </div>
                          <div className="feeds-body">
                            <h4 className="title text-warning">
                              Server Warning{" "}
                              <small className="float-right text-muted">
                                10:50
                              </small>
                            </h4>
                            <small>Your connection is not private</small>
                          </div>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="#">
                          <div className="feeds-left">
                            <i className="fa fa-check text-danger"></i>
                          </div>
                          <div className="feeds-body">
                            <h4 className="title text-danger">
                              Issue Fixed{" "}
                              <small className="float-right text-muted">
                                11:05
                              </small>
                            </h4>
                            <small>
                              WE have fix all Design bug with Responsive
                            </small>
                          </div>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="#">
                          <div className="feeds-left">
                            <i className="fa fa-shopping-basket"></i>
                          </div>
                          <div className="feeds-body">
                            <h4 className="title">
                              Aide{" "}
                              <small className="float-right text-muted">
                                11:35
                              </small>
                            </h4>
                            <small>Support.</small>
                          </div>
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  {/* <li>
                    <NavLink
                      to="#"
                      className="right_toggle icon-menu"
                      title="Right Menu"
                    >
                      <i className="icon-settings"></i>
                    </NavLink>
                  </li> */}
                  <li>
                    {/* <button onClick = {handleLogout}>
                    <i className="icon-power"></i>
                    </button> */}

                    <Button variant="secondary" onClick={handleLoad}>
                      <i className="icon-refresh"></i>
                    </Button>
                    <Button variant="warning" onClick={handleShow}>
                      <i className="icon-power"></i>
                    </Button>
                    <Modal show={show} onHide={handleClose}>
                      <Modal.Header closeButton>
                        <Modal.Title>Déconnexion</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        Vous pouvez vous déconnecter en toute sécurité!
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                          Fermer
                        </Button>
                        <Button variant="danger" onClick={handlelogout}>
                          Déconnexion
                        </Button>
                      </Modal.Footer>
                    </Modal>
                    {/* <a
                      href="#"
                      onClick={() => {
                        if (window.confirm("Voulez-vous vous déconnecter?")) {
                          handleLogout()
                        }
                      }}
                      className="icon-menu"
                    >
                      <i className="icon-power"></i>
                    </a> */}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>

        <div id="rightbar" className="rightbar">
          <ul className="nav nav-tabs-new">
            <li className="nav-item">
              <NavLink
                className="nav-link active"
                data-toggle="tab"
                to="#setting"
              >
                Settings
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" data-toggle="tab" to="#chat">
                Chat
              </NavLink>
            </li>
          </ul>
          <div className="tab-content">
            <div className="tab-pane active" id="setting">
              <div className="slim_scroll">
                <div className="card">
                  <h6>Choose Theme</h6>
                  <ul className="choose-skin list-unstyled mb-0">
                    <li data-theme="purple">
                      <div className="purple"></div>
                    </li>
                    <li data-theme="green">
                      <div className="green"></div>
                    </li>
                    <li data-theme="orange" className="active">
                      <div className="orange"></div>
                    </li>
                    <li data-theme="blue">
                      <div className="blue"></div>
                    </li>
                    <li data-theme="blush">
                      <div className="blush"></div>
                    </li>
                    <li data-theme="cyan">
                      <div className="cyan"></div>
                    </li>
                  </ul>
                </div>
                <div className="card">
                  <h6>General Settings</h6>
                  <ul className="setting-list list-unstyled mb-0">
                    <li>
                      <label className="fancy-checkbox">
                        <input
                          type="checkbox"
                          name="checkbox"
                          onChange={handlClick}
                        />
                        <span>Report Panel Usag</span>
                      </label>
                    </li>
                    <li>
                      <label className="fancy-checkbox">
                        <input
                          type="checkbox"
                          name="checkbox"
                          checked
                          onChange={handlClick}
                        />
                        <span>Email Redirect</span>
                      </label>
                    </li>
                    <li>
                      <label className="fancy-checkbox">
                        <input
                          type="checkbox"
                          name="checkbox"
                          checked
                          onChange={handlClick}
                        />
                        <span>Notifications</span>
                      </label>
                    </li>
                    <li>
                      <label className="fancy-checkbox">
                        <input
                          type="checkbox"
                          name="checkbox"
                          onChange={handlClick}
                        />
                        <span>Auto Updates</span>
                      </label>
                    </li>
                  </ul>
                </div>
                <div className="card">
                  <h6>Account Settings</h6>
                  <ul className="setting-list list-unstyled mb-0">
                    <li>
                      <label className="fancy-checkbox">
                        <input
                          type="checkbox"
                          name="checkbox"
                          onChange={handlClick}
                        />
                        <span>Offline</span>
                      </label>
                    </li>
                    <li>
                      <label className="fancy-checkbox">
                        <input
                          type="checkbox"
                          name="checkbox"
                          checked
                          onChange={handlClick}
                        />
                        <span>Location Permission</span>
                      </label>
                    </li>
                    <li>
                      <label className="fancy-checkbox">
                        <input
                          type="checkbox"
                          name="checkbox"
                          checked
                          onChange={handlClick}
                        />
                        <span>Notifications</span>
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="tab-pane right_chat" id="chat">
              <div className="slim_scroll">
                <form>
                  <div className="input-group m-b-20">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="icon-magnifier"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                      onChange={handlClick}
                    />
                  </div>
                </form>
                <div className="card">
                  <h6>Recent</h6>
                  <ul className="right_chat list-unstyled mb-0">
                    <li className="online">
                      <NavLink to="#">
                        <div className="media">
                          <img
                            className="media-object "
                            src="../assets/images/xs/avatar4.jpg"
                            alt=""
                          />
                          <div className="media-body">
                            <span className="name">
                              Ava Alexander{" "}
                              <small className="float-right">Just now</small>
                            </span>
                            <span className="message">
                              Lorem ipsum Veniam aliquip culpa laboris minim
                              tempor
                            </span>
                            <span className="badge badge-outline status"></span>
                          </div>
                        </div>
                      </NavLink>
                    </li>
                    <li className="online">
                      <NavLink to="#">
                        <div className="media">
                          <img
                            className="media-object "
                            src="../assets/images/xs/avatar5.jpg"
                            alt=""
                          />
                          <div className="media-body">
                            <span className="name">
                              Debra Stewart{" "}
                              <small className="float-right">38min ago</small>
                            </span>
                            <span className="message">
                              Many desktop publishing packages and web page
                              editors
                            </span>
                            <span className="badge badge-outline status"></span>
                          </div>
                        </div>
                      </NavLink>
                    </li>
                    <li className="offline">
                      <NavLink to="#">
                        <div className="media">
                          <img
                            className="media-object "
                            src="../assets/images/xs/avatar2.jpg"
                            alt=""
                          />
                          <div className="media-body">
                            <span className="name">
                              Susie Willis{" "}
                              <small className="float-right">2hr ago</small>
                            </span>
                            <span className="message">
                              Contrary to belief, Lorem Ipsum is not simply
                              random text
                            </span>
                            <span className="badge badge-outline status"></span>
                          </div>
                        </div>
                      </NavLink>
                    </li>
                    <li className="offline">
                      <NavLink to="#">
                        <div className="media">
                          <img
                            className="media-object "
                            src="../assets/images/xs/avatar1.jpg"
                            alt=""
                          />
                          <div className="media-body">
                            <span className="name">
                              Folisise Chosielie{" "}
                              <small className="float-right">2hr ago</small>
                            </span>
                            <span className="message">
                              There are many of passages of available, but the
                              majority
                            </span>
                            <span className="badge badge-outline status"></span>
                          </div>
                        </div>
                      </NavLink>
                    </li>
                    <li className="online">
                      <NavLink to="#">
                        <div className="media">
                          <img
                            className="media-object "
                            src="../assets/images/xs/avatar3.jpg"
                            alt=""
                          />
                          <div className="media-body">
                            <span className="name">
                              Marshall Nichols{" "}
                              <small className="float-right">1day ago</small>
                            </span>
                            <span className="message">
                              It is a long fact that a reader will be distracted
                            </span>
                            <span className="badge badge-outline status"></span>
                          </div>
                        </div>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="left-sidebar" className="sidebar">
          <div className="navbar-brand">
            <NavLink to="/home">
              <img
                src="../assets/images/logos/logo_31jours_2.png"
                alt="31 jours Logo"
                className="img-fluid logo"
              />
              <span>P31 jours</span>
            </NavLink>
            <button
              type="button"
              className="btn-toggle-offcanvas btn btn-sm btn-default float-right"
            >
              <i className="lnr lnr-menu fa fa-chevron-circle-left"></i>
            </button>
          </div>
          <div className="sidebar-scroll">
            <div className="user-account">
              <div className="user_div">
                <img
                  src={"../assets/images/logos/avatar-man.png"}
                  className={"user-photo"}
                  alt={"User Profile Picture"}
                />
              </div>
              <div className="dropdown">
                <span>Bienvenue,</span>
                <NavLink
                  to="/home"
                  className="dropdown-toggle user-name"
                  data-toggle="dropdown"
                >
                  <strong>{name}</strong>
                </NavLink>
                <ul className="dropdown-menu dropdown-menu-right account">
                  <li>
                    <NavLink to="/home">
                      <i className="icon-user"></i> Profile
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink to="/home">
                      <i className="icon-envelope-open"></i>Messages
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/home">
                      <i className="icon-settings"></i>Paramètres
                    </NavLink>
                  </li> */}
                  <li className="divider"></li>
                  <li>
                    {/* <NavLink to="/logout">
                      <i className="icon-power"></i>Déconnexion
                    </NavLink> */}
                    {/* <a href="#" onClick={() => {
                      if (window.confirm("Voulez-vous vous déconnecter?")) {
                        handleLogout()
                      }
                    }}> */}
                    {/* <Button>
                      <i className="icon-power"></i> Déconnexion
                    </Button> */}
                    <Button variant="danger" onClick={handleShow}>
                      <i className="icon-power"> Déconnexion</i>
                    </Button>
                    <Modal show={show} onHide={handleClose}>
                      <Modal.Header closeButton>
                        <Modal.Title>Déconnexion</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        Vous pouvez vous déconnecter en toute sécurité!
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                          Fermer
                        </Button>
                        <Button variant="danger" onClick={handlelogout}>
                          Déconnexion
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </li>
                </ul>
              </div>
            </div>
            <nav id="left-sidebar-nav" className="sidebar-nav">
              <ul id="main-menu" className="metismenu">
                <li className="tab">
                  <NavLink to="/home">
                    <i className="icon-home"></i>
                    <span>Tableau de bord</span>
                  </NavLink>
                </li>

                <li>
                  <a href="#forms" className="has-arrow">
                    <i className="fa fa-address-card"></i>
                    <span>Gestion des NC</span>
                  </a>
                  <ul>
                    <li>
                      <NavLink to="/nc-liste-principale">
                        <span>Liste principale</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/nc-mes-saisies">
                        <span>Mes saisies</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={"#"}
                        className=""
                        data-toggle="modal"
                        data-target="#addNc"
                      >
                        Ajout de NC
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/pointage-bilans">
                        <span>Pointage</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/attribution-coach">
                        <span>Attribution coach</span>
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="#forms" className="has-arrow">
                    <i className="fa fa-phone-square"></i>
                    <span>Suivi Téléphonique</span>
                  </a>
                  <ul>
                    <li>
                      <NavLink to="/suivi-principal">
                        <span>Ma liste d'appels</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/suivi-perseverance">
                        <span>Liste persévérance</span>
                      </NavLink>
                    </li>
                  </ul>
                </li>
                {/* <li>
                  <a href="#forms" className="has-arrow">
                    <i className="icon-users"></i>
                    <span>Plannings</span>
                  </a>
                  <ul>
                    <li>
                      <NavLink to="/planning">
                        <i className="icon-users"></i>
                        <span>Planning</span>
                      </NavLink>
                    </li>
                  </ul>
                </li> */}
                <li>
                  <a href="#forms" className="has-arrow">
                    <i className="fa fa-certificate"></i>
                    <span>Gestion des certificats</span>
                  </a>
                  <ul>
                    <li>
                      <NavLink to="/certificats-reussite">
                        <span>certificats</span>
                      </NavLink>
                    </li>
                  </ul>
                </li>

                <li>
                  <a href="#forms" className="has-arrow">
                    <i className="fa fa-comments"></i>
                    <span>Messagerie</span>
                  </a>
                  <ul>
                    <li>
                      <NavLink to="/messages">
                        <span>messages</span>
                      </NavLink>
                    </li>
                  </ul>
                </li>

                <li>
                  <a href="#forms" className="has-arrow">
                    <i className="fa fa-graduation-cap"></i>
                    <span>Formation </span>
                  </a>
                  <ul>
                    <li>
                      <NavLink to="/formations">
                        <span>formations</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="">
                        <span>Suivi Formation</span>
                      </NavLink>
                    </li>
                  </ul>
                </li>

                <li>
                  <a href="#forms" className="has-arrow">
                    <i className="fa fa-unlock-alt"></i>
                    <span>Administration</span>
                  </a>
                  <ul>
                    <li>
                      <NavLink to="/list-users">
                        <span>Utilisateurs</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/members">
                        <span>Membres</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/roles">
                        <span>Roles</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/permissions">
                        <span>Permissions</span>
                      </NavLink>
                    </li>
                  </ul>
                </li>

                {/* <li>
                  <a href="#forms" className="has-arrow">
                    <i className="icon-users"></i>
                    <span>Authentification</span>
                  </a>
                  <ul>
                    <li>
                      <NavLink to="/list-users">
                        <i className="icon-users"></i>
                        <span>Utilisateurs</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/users">
                        <i className="icon-users"></i>
                        <span>Roles</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/users">
                        <i className="icon-users"></i>
                        <span>Permissions</span>
                      </NavLink>
                    </li>
                  </ul>
                </li> */}

                {/* <li>
                  <a href="#users" className="has-arrow">
                    <i className="icon-users"></i>
                    <span>Utilisateurs</span>
                  </a>
                  <ul>
                    <li>
                      <NavLink to="/list-users">
                        <i className="icon-users"></i>
                        <span>Lister les utilisateurs</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/list-source-contacts">
                        <i className="icon-users"></i>
                        <span>Source contacts</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/list-statut-appels">
                        <i className="icon-users"></i>
                        <span>Statut appel</span>
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="#forms" className="has-arrow">
                    <i className="icon-users"></i>
                    <span>Admin contacts</span>
                  </a>
                  <ul>
                    <li>
                      <NavLink to="/type-list-contacts">
                        <i className="icon-users"></i>
                        <span>Type de contacts</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/list-source-contacts">
                        <i className="icon-users"></i>
                        <span>Source contacts</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/list-statut-appels">
                        <i className="icon-users"></i>
                        <span>Statut appel</span>
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="#forms" className="has-arrow">
                    <i className="icon-users"></i>
                    <span>Administration</span>
                  </a>
                  <ul>
                    <li>
                      <NavLink to="/program-list">
                        <i className="icon-users"></i>
                        <span>Programmes</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/bilan-list">
                        <i className="icon-users"></i>
                        <span>Bilan</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/users">
                        <i className="icon-users"></i>
                        <span>Formation</span>
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li>
                  <NavLink to="/contacts">
                    <i className="icon-user"></i>
                    <span>Gestion des contacts</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/chat">
                    <i className="icon-bubbles"></i>
                    <span>Sessions de Bilan</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="">
                    <i className="icon-calendar"></i>
                    <span>Planning Bilans</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="">
                    <i className="icon-bubbles"></i>
                    <span>Suivi Formation</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="">
                    <i className="icon-bubbles"></i>
                    <span>Suivi téléphonique</span>
                  </NavLink>
                </li> */}
              </ul>
            </nav>
          </div>
        </div>

        {/* <div id="main-content">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-md-6 col-sm-12">
                <h2>Dashboard</h2>
              </div>
              <div className="col-md-6 col-sm-12 text-right">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <NavLink to="index.html">
                      <i className="icon-home"></i>
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item active">Dashboard</li>
                </ul>
                <NavLink
                  to="#"
                  className="btn btn-sm btn-primary"
                  title=""
                >
                  Create New
                </NavLink>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-12">
                <div className="card top_report">
                  <div className="row clearfix">
                    <div className="col-lg-3 col-md-6 col-sm-6">
                      <div className="body">
                        <div className="clearfix">
                          <div className="float-left">
                            <i className="fa fa-2x fa-dollar text-col-blue"></i>
                          </div>
                          <div className="number float-right text-right">
                            <h6>Earnings</h6>
                            <span className="font700">$22,500</span>
                          </div>
                        </div>
                        <div className="progress progress-xs progress-transparent custom-color-blue mb-0 mt-3">
                          <div
                            className="progress-bar"
                            data-transitiongoal="87"
                          ></div>
                        </div>
                        <small className="text-muted">
                          19% compared to last week
                        </small>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                      <div className="body">
                        <div className="clearfix">
                          <div className="float-left">
                            <i className="fa fa-2x fa-bar-chart-o text-col-green"></i>
                          </div>
                          <div className="number float-right text-right">
                            <h6>Sales</h6>
                            <span className="font700">$500</span>
                          </div>
                        </div>
                        <div className="progress progress-xs progress-transparent custom-color-green mb-0 mt-3">
                          <div
                            className="progress-bar"
                            data-transitiongoal="28"
                          ></div>
                        </div>
                        <small className="text-muted">
                          19% compared to last week
                        </small>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                      <div className="body">
                        <div className="clearfix">
                          <div className="float-left">
                            <i className="fa fa-2x fa-shopping-cart text-col-red"></i>
                          </div>
                          <div className="number float-right text-right">
                            <h6>Orders</h6>
                            <span className="font700">215</span>
                          </div>
                        </div>
                        <div className="progress progress-xs progress-transparent custom-color-red mb-0 mt-3">
                          <div
                            className="progress-bar"
                            data-transitiongoal="41"
                          ></div>
                        </div>
                        <small className="text-muted">
                          19% compared to last week
                        </small>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                      <div className="body">
                        <div className="clearfix">
                          <div className="float-left">
                            <i className="fa fa-2x fa-thumbs-up text-col-yellow"></i>
                          </div>
                          <div className="number float-right text-right">
                            <h6>Likes</h6>
                            <span className="font700">21,215</span>
                          </div>
                        </div>
                        <div className="progress progress-xs progress-transparent custom-color-yellow mb-0 mt-3">
                          <div
                            className="progress-bar"
                            data-transitiongoal="75"
                          ></div>
                        </div>
                        <small className="text-muted">
                          19% compared to last week
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row clearfix">
              <div className="col-lg-8 col-md-12">
                <div className="card">
                  <div className="header bline">
                    <h2>Cryptocurrency Overview</h2>
                    <ul className="header-dropdown dropdown dropdown-animated scale-left">
                      <li>
                        <NavLink
                          className="btn btn-default btn-sm"
                          to="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Monthly"
                        >
                          Monthly
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="btn btn-outline-primary btn-sm"
                          to="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Yearly"
                        >
                          Yearly
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                  <div className="body">
                    <div
                      id="User_Statistics"
                      className="mt-2"
                      style={{height: "290px"}}
                    ></div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12">
                <div className="card">
                  <div className="header">
                    <h2>Top Products</h2>
                    <ul className="header-dropdown dropdown dropdown-animated scale-left">
                      <li>
                        {" "}
                        <NavLink
                          to="#"
                          data-toggle="cardloading"
                          data-loading-effect="pulse"
                        >
                          <i className="icon-refresh"></i>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="#" className="full-screen">
                          <i className="icon-size-fullscreen"></i>
                        </NavLink>
                      </li>
                      <li className="dropdown">
                        <NavLink
                          to="#"
                          className="dropdown-toggle"
                          data-toggle="dropdown"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                        ></NavLink>
                        <ul className="dropdown-menu">
                          <li>
                            <NavLink to="#">Action</NavLink>
                          </li>
                          <li>
                            <NavLink to="#">Another Action</NavLink>
                          </li>
                          <li>
                            <NavLink to="#">Something else</NavLink>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="body">
                    <div id="chart-top-products" className="chartist"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Nav;
