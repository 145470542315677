import React from "react";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGrid, GridToolbar, frFR } from "@mui/x-data-grid";
import { useDemoData } from "@mui/x-data-grid-generator";
import { toast, ToastContainer } from "react-toastify";
import SuiviTelephoniqueService from "../../services/suivi-telephonique-service";
import EditSuiviTelephonique from "../../pages/appel/edit-suivi-telephonique";

const VISIBLE_FIELDS = [
  "id",
  "name",
  "firstname",
  "email",
  "contact",
  "statut_appel",
  "commentaires",
  "subscription_date",
  "created_at",
];
const DataTableTelephonique = ({ contacts, statuts, sendUpdate }) => {
  const [pageSize, setPageSize] = React.useState(5);
  const columns = [
    {
      field: "id",
      headerClassName: "header-prog31",
      hide: true,
      headerName: "Id",
      flex: 1,
    },
    {
      field: "name",
      headerClassName: "header-prog31",
      hide: true,
      headerName: "Nom",
      flex: 1,
    },
    {
      field: "firstname",
      headerClassName: "header-prog31",
      hide: true,
      headerName: "Prenom",
      flex: 1,
    },
    {
      field: "email",
      headerClassName: "header-prog31",
      hide: true,
      headerName: "Email",
      flex: 1,
    },
    {
      field: "contact",
      headerClassName: "header-prog31",
      headerName: "Contact",
      flex: 1,
    },
    {
      field: "date_appel",
      headerClassName: "header-prog31",
      headerName: "Date d'appel",
      flex: 1,
    },
    {
      field: "statut_appel",
      headerClassName: "header-prog31",
      headerName: "Statut d'appel",
      flex: 1,
    },
    {
      field: "commentaires",
      type: "",
      headerClassName: "header-prog31",
      headerName: "Commentaires",
      flex: 1,
    },
    {
      field: "subscription_date",
      hide: true,
      headerClassName: "header-prog31",
      headerName: "Date de saisie",
      flex: 1,
    },
    {
      field: "created_at",
      hide: true,
      headerClassName: "header-prog31",
      headerName: "Créé le",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Actions",
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "header-prog31",
      renderCell: (id) => (
        <>
          <EditSuiviTelephonique
            nc={id.row}
            id={id}
            statuts={statuts}
            sendUpdate={sendUpdate}
          />
          <Button
            id="deleteBtn"
            style={{
              backgroundColor: "#e8605d",
              color: "#fff",
              padding: "7px 7px",
            }}
            onClick={() => {
              if (
                window.confirm(
                  "Êtes-vous sûr de vouloir supprimer cet enregistrement ?"
                )
              ) {
                handleDelete(id);
              }
            }}
            variant="contained"
            color="primary"
            type="submit"
          >
            <DeleteIcon />
          </Button>
        </>
      ),
    },
  ];

  const { data } = useDemoData({
    dataSet: "Commodity",
    rowLength: 100,
    maxColumns: 6,
    visibleFields: VISIBLE_FIELDS,
  });

  const handleDelete = (id) => {
    SuiviTelephoniqueService.remove(id.id).then((data) => {
      console.log(data);
      toast.success("Contact supprimé!", {
        autoClose: 2000, // Durée d'affichage du toast en millisecondes (2 secondes dans cet exemple)
        onClose: () => {},
      });
      sendUpdate(true);
    });
  };
  return (
    <div style={{ height: 450, width: "100%" }}>
      <DataGrid
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        {...data}
        initialState={{
          sorting: {
            sortModel: [{ field: "id", sort: "desc" }],
          },
        }}
        components={{ Toolbar: GridToolbar }}
        rows={contacts}
        columns={columns}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20]}
        pagination
      />
      <ToastContainer />
    </div>
  );
};

export default DataTableTelephonique;
