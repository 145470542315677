import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import DataTableAttente from "../../components/layouts/data-table-attente";
import ContactService from "../../services/contact-service";
import AddNc from "./add-nc";
import moment from "moment";

const ListSaisies = () => {
  const initialState = [];
  const [contacts, setContacts] = useState(initialState);
  const [secteurs, setSecteurs] = useState(initialState);
  const [listes, setListes] = useState(initialState);
  const [sources, setSources] = useState(initialState);
  const [doUpdate, setDoUpdate] = useState(true);

  useEffect(() => {
    if (doUpdate) {
      ContactService.getSecteurs().then((datas) =>
        setSecteurs(Object.values(datas))
      );
      ContactService.listTypes().then((data) => setListes(data.data));
      ContactService.getSources().then((data) => setSources(data.data));
      ContactService.getMesSaisies().then((datas) => {
        const items = datas.data.map((a) => ({
          ...{
            id: a.id,
            civilite: a.civilite,
            lastname: a.lastname,
            firstname: a.firstname,
            email: a.email,
            telephone: a.telephone,
            etat_mineur: a.est_mineur === 1 ? "Adulte" : "Ado",
            est_mineur: a.est_mineur,
            metier: a.metier,
            author_id: a.author_id,
            created_at: moment(a.created_at).format("yyyy-MM-DD"),
            subscription_date: moment(a.subscription_date).format("yyyy-MM-DD"),
            genre: a.genre,
            birthdate: moment(a.birthdate).format("yyyy-MM-DD"),
            secteur_gf: a.secteur_gf,
            ville: a.ville,
            code_postal: a.code_postal,
            adresse_postale: a.adresse_postale,
            source: a.source,
            source_id: a?.source.source_id,
            liste_id: a.liste.liste_id,
            liste: a.liste,
            libelle_source: a.source.libelle,
            libelle_liste: a.liste.libelle,
          },
        }));
        setContacts(items);
        setDoUpdate(false);
      });
    }
  }, [doUpdate]);

  const sendUpdate = (value) => {
    setDoUpdate(value);
  };

  return (
    <div id="main-content">
      <div className="block-header">
        <div className="row clearfix">
          <div className="col-md-6 col-sm-12">
            <h4 className="text-31-secondary">Mes saisies en attente</h4>
          </div>
          <div className="col-md-6 col-sm-12 text-right">
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/home">
                  <i className="icon-home"></i>
                </a>
              </li>
              <li className="breadcrumb-item active">Gestion des NC</li>
              <li className="breadcrumb-item active">Mes Saisies</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row clearfix">
          <div className="col-lg-12">
            <div className="card">
              <div className="body">
                <div className="table-responsive">
                  <div className="text-right mb-2">
                    <AddNc sendUpdate={sendUpdate} />
                  </div>
                  <DataTableAttente
                    contacts={contacts}
                    secteurs={secteurs}
                    listes={listes}
                    sources={sources}
                    sendUpdate={sendUpdate}
                  />
                  <ToastContainer />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListSaisies;
