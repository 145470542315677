import { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Button } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import AffectationService from "../../services/affectation-service";
import SuiviTelephoniqueService from "../../services/suivi-telephonique-service";
import moment from "moment";

const AddAppel = (props) => {
  const initialState = {
    contact_id: "",
    contact: "",
    coach_appel: "",
    date_appel: "",
    statut_id: "",
    commentaires: "",
  };
  const [appels, setAppels] = useState(initialState);
  const [affectations, setAffectations] = useState([]);
  const [statut, setStatut] = useState([]);
  const [show, setShow] = useState(false);
  useEffect(() => {
    SuiviTelephoniqueService.getStatutAppels().then((datas) => {
      setStatut(datas.data);
    });
    AffectationService.getMesAffectations().then((datas) => {
      setAffectations(datas.data);
    });
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    // console.log(name + " " + value);
    setAppels((appels) => ({ ...appels, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newContacts = { ...appels };
    newContacts.date_appel = moment(appels.date_appel).format("yyyy-MM-DD");
    newContacts.coach_id = JSON.parse(localStorage.getItem("userId"));
    setAppels(newContacts);
    SuiviTelephoniqueService.add(newContacts)
      .then((data) => {
        console.log(data);
        toast.success("Insertion réussie!");
        props.sendUpdate(true);
      })
      .catch((e) => {
        console.log(e);
        toast.error("Problème observé lors de l'insertion!");
      });
  };
  return (
    <>
      <Button variant="contained" color="primary" onClick={handleShow}>
        <i className="fa fa-plus">&nbsp;</i>
        Ajouter
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Ajout d'appel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group
              className="mb-3 formContol"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>Contact</Form.Label>
              <Form.Select
                className="form-control"
                name="contact_id"
                value={appels.contact_id}
                onChange={handleChange}
              >
                <option>Choisir ...</option>
                {affectations &&
                  affectations.map((c) => (
                    <option key={c.contact.id} value={c.contact.id}>
                      {c.contact.firstname} {c.contact.lastname}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Coach</Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                name="coach_appel"
                value={affectations[0]?.coach.name}
                onChange={handleChange}
                readOnly
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Date d'appel</Form.Label>
              <Form.Control
                type="date"
                name="date_appel"
                value={appels.date_appel}
                onChange={handleChange}
                placeholder="Entrez la date d'appel"
              />
            </Form.Group>

            <Form.Group
              className="mb-3 formContol"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>Statut d'appel</Form.Label>
              <Form.Select
                className="form-control"
                name="statut_id"
                value={appels.statut_id}
                onChange={handleChange}
              >
                <option>Choisir ...</option>
                {Array.isArray(statut) &&
                  statut.map((s) => (
                    <option key={s.id} value={s.id}>
                      {s.libelle}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Commentaires</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="commentaires"
                value={appels.commentaires}
                onChange={handleChange}
                placeholder="Entrez votre commentaire"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            className="ml-1 mr-1"
          >
            Ajouter
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleClose}
            className="ml-1 mr-1"
          >
            Fermer
          </Button>
        </Modal.Footer>
        <ToastContainer />
      </Modal>
    </>
  );
};

export default AddAppel;
