import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "@mui/material";
import AffecService from "../../services/suivi-contacts-service";
import { Edit } from "@mui/icons-material";
import { toast } from "react-toastify";

const EditAffectation = (props) => {
  const [contacts, setContacts] = useState([]);
  const [users, setUsers] = useState([]);
  const [affectation, setAffectation] = useState([]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setAffectation(props.affectation);
    setContacts(props.contacts);
    setUsers(props.users);
  }, [props.affectation, props.contacts, props.users]);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const handleCommentaire = (e) => {
    setAffectation({ ...affectation, commentaires: e.target.value });
  };
  const handleDateFin = (e) => {
    setAffectation({ ...affectation, date_fin: e.target.value });
  };
  const handleDateDebut = (e) => {
    setAffectation({ ...affectation, date_debut: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    AffecService.update(affectation.id, affectation)
      .then((data) => {
        toast.success("Modification réussie!");
        props.sendUpdate(true);
      })
      .catch((e) => {
        toast.error("Problème observé lors de la modification!");
      });
    console.log(affectation);
    return;
  };

  return (
    <>
      <Button
        variant="contained"
        color="warning"
        onClick={handleShow}
        className="m-1 text-white"
      >
        <Edit />
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modification affectation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="" method="post">
            <div className="form-outline">
              <label className="form-label" htmlFor="coach">
                Coach
              </label>
              <select
                id="coach_id"
                className="form-control"
                defaultValue={affectation.coach_id}
                onChange={(e) => console.log(e.target.value)}
              >
                {users.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.coach}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-outline">
              <label className="form-label" htmlFor="contact">
                Contact
              </label>
              <select
                id="contact_id"
                className="form-control"
                defaultValue={affectation.contact_id}
                onChange={(e) => console.log(e.target.value)}
              >
                {contacts.map((contact) => (
                  <option key={contact.id} value={contact.id}>
                    {contact.fullname}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-outline">
              <label className="form-label" htmlFor="date_fin">
                Date de Début
              </label>
              <input
                type="date"
                id="debut"
                name=""
                className="form-control"
                value={affectation.date_debut}
                onChange={handleDateDebut}
              />
            </div>
            <div className="form-outline">
              <label className="form-label" htmlFor="date_fin">
                Date de fin
              </label>
              <input
                type="date"
                id="debut"
                name=""
                className="form-control"
                value={affectation.date_fin || ""}
                onChange={handleDateFin}
              />
            </div>
            <div className="form-outline">
              <label htmlFor="commentaires" className="form-label">
                Commentaires
              </label>
              <textarea
                className="form-control"
                id="commentaires"
                rows="3"
                onChange={handleCommentaire}
                defaultValue={affectation.commentaires || ""}
              ></textarea>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outlined" color="primary" onClick={handleSubmit}>
            Modifier
          </Button>
          <Button
            variant="outlined"
            color="error"
            className="ml-2"
            onClick={handleClose}
          >
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditAffectation;
