import React from "react";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGrid, GridToolbar, frFR } from "@mui/x-data-grid";
import { useDemoData } from "@mui/x-data-grid-generator";
import SuiviContactService from "../../services/suivi-contacts-service";
import EditAffectation from "../../pages/affectations/edit-affectation";
import { toast } from "react-toastify";

const VISIBLE_FIELDS = [
  "id",
  "contact_id",
  "coach_id",
  "created_at",
  "date_debut",
  "date_fin",
  "commentaires",
];
const DataTableAffec = ({ affectations, users, contacts, sendUpdate }) => {
  const [pageSize, setPageSize] = React.useState(5);
  const columns = [
    {
      field: "id",
      hide: true,
      headerName: "Id",
      flex: 1,
      headerClassName: "header-prog31",
    },
    {
      field: "contact_id",
      hide: true,
      headerName: "ContactId",
      flex: 1,
      headerClassName: "header-prog31",
    },
    {
      field: "coach_id",
      hide: true,
      headerName: "CoachId",
      flex: 1,
      headerClassName: "header-prog31",
    },
    {
      field: "contact",
      headerName: "Contact",
      flex: 1,
      headerClassName: "header-prog31",
    },
    {
      field: "coach",
      headerName: "Coach",
      flex: 1,
      headerClassName: "header-prog31",
    },
    {
      field: "subscription_date",
      hide: true,
      headerName: "Date de saisie",
      flex: 1,
      headerClassName: "header-prog31",
    },
    {
      field: "created_at",
      hide: true,
      headerName: "Créé le",
      flex: 1,
      headerClassName: "header-prog31",
    },
    {
      field: "date_debut",
      headerName: "Date début",
      flex: 1,
      headerClassName: "header-prog31",
    },
    {
      field: "date_fin",
      headerName: "Date fin",
      flex: 1,
      headerClassName: "header-prog31",
    },
    {
      field: "commentaires",
      headerName: "Commentaires",
      flex: 1,
      headerClassName: "header-prog31",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      headerClassName: "header-prog31",

      // Important: passing id from customers state so I can delete or edit each user
      renderCell: (id) => (
        <>
          <EditAffectation
            affectation={id.row}
            id={id}
            show={true}
            users={users}
            contacts={contacts}
            sendUpdate={sendUpdate}
          />
          <Button
            id="deleteBtn"
            style={{
              backgroundColor: "#e8605d",
              color: "#fff",
              padding: "7px 7px",
            }}
            onClick={() => {
              if (
                window.confirm(
                  "Êtes-vous sûr de vouloir supprimer cet enregistrement ?"
                )
              ) {
                handleDelete(id);
              }
            }}
            variant="contained"
            color="primary"
            type="submit"
          >
            <DeleteIcon />
          </Button>
        </>
      ),
    },
  ];

  const { data } = useDemoData({
    dataSet: "Commodity",
    rowLength: 100,
    maxColumns: 6,
    visibleFields: VISIBLE_FIELDS,
  });
  
  const handleDelete = (user) => {
    console.log(user.id);
    SuiviContactService.remove(user.id)
      .then((data) => {
        console.log(data);
        toast.success("Affectation supprimé !!", { autoClose: 2000 });
        sendUpdate(true);
      })
      .catch((e) => {
        toast.error(e.response.data.description, {
          autoClose: 3000,
          onClose: () => {}, // TODO, mise à jour des écrans
        });
      });
  };

  return (
    <div style={{ height: 450, width: "100%" }}>
      <DataGrid
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        {...data}
        initialState={{
          sorting: {
            sortModel: [{ field: "id", sort: "desc" }],
          },
        }}
        components={{ Toolbar: GridToolbar }}
        rows={affectations}
        columns={columns}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20]}
        pagination
      />
    </div>
  );
};

export default DataTableAffec;
