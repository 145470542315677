import React, { useState } from "react";
import UserService from "../../services/user-service";
import { ToastContainer, toast } from 'react-toastify';

const AddUser = (props) => {
  const initialState = {
    name: "",
    email: "",
    password: "",
    roles: [],
    firstname: "",
    mobile_phone: "",
    genre: "",
    civilite: "",
    civil_status: "",
    description: ""
  };
  const [user, setUser] = useState(initialState);
  const handleChange = (event) => {
    event.preventDefault();
    const name = event.target.name;
    const value = event.target.value;
    let data = { ...user, [name]: value }
    //data.roles = ["1"];
    data.roles = [parseInt(data.roles)];
    console.log(data);
    setUser(data);
    console.log(user)
  };
  /**
   * export default Checklist = () => {
  const [listOfItems, setListOfItems] = useState([
    {name: 'bubbles', isChecked: true},
    {name: 'gregory', isChecked: false}
  ]);

  const updateListOfItems = (itemIndex, newsChecked) => {
    const updatedListOfItems = [...listOfItems];
    updatedListOfItems[itemIndex].isChecked = isChecked;
    setListOfItems(updatedListOfItems);
  }

  return (
    {listOfitems.map((item, index) =>
      <index key={index} type="checkbox" checked={item.isChecked} onChange={() => updateListOfItems(index, !item.isChecked)} />
    )}
  )
}
   */
  console.log(user);
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(user);
    UserService.add(user).then((data) => console.log(data));
    toast.success("Insertion réussie!");
   // window.location.reload();
  };
  return (
    <>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Ajout d'un utilisateur
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="form-group col">
                    <label htmlFor="civile">Civilité:</label>
                    <select
                      className="form-control"
                      id="civile"
                      name="civilite"
                      value={user.civilite}
                      onChange={handleChange}
                    >
                      <option hidden>Choisir la civilité</option>
                      <option value="madame">Madame</option>
                      <option value="mademoiselle">Mademoiselle</option>
                      <option value="monsieur">Monsieur</option>
                    </select>
                  </div>
                  <div className="form-group col">
                    <label htmlFor="statut">Statut:</label>
                    <select
                      className="form-control"
                      id="statut"
                      name="civil_status"
                      value={user.civil_status}
                      onChange={handleChange}
                    >
                      <option hidden>Choisir le statut</option>
                      <option value="célibataire">Célibataire</option>
                      <option value="marié(e)">Marié(e)</option>
                      <option value="veuf(ve)">Veuf(ve)</option>
                      <option value="divorcé(e)">Divorcé(e)</option>
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col">
                    <label htmlFor="nom">Nom:*</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Entrer le nom"
                      id="nom"
                      name="name"
                      required
                      value={user.name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col">
                    <label htmlFor="prenom">Prénom:*</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Entrer le prénom"
                      id="prenom"
                      name="firstname"
                      required
                      value={user.firstname}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col">
                    <label htmlFor="email">Email adresse:*</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Entrer l'email adresse"
                      id="email"
                      name="email"
                      value={user.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group col">
                    <label htmlFor="password">Mot de passe:*</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Entrer le mot de passe"
                      id="password"
                      name="password"
                      value={user.password}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  {/* <div className="form-group col">
                    <label htmlFor="tel">Téléphone</label>
                    <input
                      type="tel"
                      className="form-control"
                      placeholder="Entrer le téléphone"
                      id="tel"
                      name="mobile_phone"
                      value={user.mobile_phone}
                      onChange={handleChange}
                    />
                  </div> */}
                </div>

                  <div className="row">
                    <div className="form-group col">
                      <label htmlFor="genre">Genre:</label>
                      <select
                        className="form-control"
                        id="genre"
                        name="genre"
                        value={user.genre}
                        onChange={handleChange}
                      >
                        <option hidden>Choisir le genre</option>
                        <option value="feminin">Feminin</option>
                        <option value="masculin">Masculin</option>
                      </select>
                    </div>
                    <div className="form-group col">
                    <label htmlFor="tel">Téléphone</label>
                    <input
                      type="tel"
                      className="form-control"
                      placeholder="Entrer le téléphone"
                      id="tel"
                      name="mobile_phone"
                      value={user.mobile_phone}
                      onChange={handleChange}
                    />
                  </div>
                    {/* <div className="form-group col">
                      <label htmlFor="roles">Rôles:</label>
                      <select
                        className="form-control"
                        id="roles"
                        name="roles"
                        value={user.roles}
                        multiple={false}
                        onChange={handleChange}
                      
                      >
                        <option hidden>Choisir les rôles</option>
                        <option value="1">Administrateur</option>
                        <option value="2">Responsable</option>
                        <option value="3">Utilisateur</option>
                      </select>
                    </div> */}
                  </div>
                  <div className="form-group col">
                  <h6>Rôles</h6>
                  <div className="fancy-checkbox">
                    <label className="fancy-checkbox">
                      <input
                        onChange={handleChange}
                        name="roles"
                        type="checkbox"
                        value={1}
                      />
                      <span>Admin système</span>
                    </label>

                    <label className="fancy-checkbox">
                      <input
                        onChange={handleChange}
                        name="roles"
                        type="checkbox"
                        value={2}
                      />
                      <span>Utilisateur</span>
                    </label>

                    <label className="fancy-checkbox">
                      <input
                        onChange={handleChange}
                        name="roles"
                        type="checkbox"
                        value={3}
                      />
                      <span>Apprenti</span>
                    </label>
                  </div>
                </div>
                <div><input type="hidden" value="N.A" name={user.description} onChange={handleChange}/></div>
                <div className="">
                  <button type="submit" className="btn btn-primary btn-block" >
                    Soumettre
                  </button>
                  <button type="button" className="btn btn-secondary btn-block" data-dismiss="modal" onClick={()=>window.location.reload()}>
                    Fermer
                  </button>
                  <ToastContainer/>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddUser;
