import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import WithoutNav from "../../helpers/without-nav";
import WithNav from "../../helpers/with-nav";
import Home from "../../pages/home";
import NotFound from "../../pages/not-found";
import Login from "../auth/login";
import Profile from "../auth/profile";
import Register from "../auth/register";
import ListUsers from "../../pages/users/list-users";
import ListNc from "../../pages/gestionNc/list-nc";
import Pointage from "../../pages/pointage/pointage";
import Certificat from "../../pages/certif/certificat";
import ListAffectations from "../../pages/affectations/list-affectations";
import PrivatedRoute from "./privated-route";
import Certificat2 from "../../pages/certif/certificat2";
import Perseverance from "../../pages/appel/list-perseverance";
import ListSaisies from "../../pages/gestionNc/list-saisies";
import ListAppel from "../../pages/appel/list-appel";

export function Header(props) {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<WithoutNav />}>
          <Route index  path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/profile" element={<Profile />} />
        </Route>
        <Route element={<WithNav />}>
          <Route path="/home" element={<PrivatedRoute><Home /> </PrivatedRoute>} />
          <Route path="/list-users" element={<PrivatedRoute><ListUsers /></PrivatedRoute>} />
          <Route path="/nc-liste-principale" element={<PrivatedRoute><ListNc /></PrivatedRoute>} />
          <Route path="/attribution-coach" element={<PrivatedRoute><ListAffectations /></PrivatedRoute>} />
          <Route path="/pointage-bilans" element={<PrivatedRoute><Pointage /></PrivatedRoute>} />
          <Route path="/certificats-reussite" element={<PrivatedRoute><Certificat /></PrivatedRoute>} />
          <Route path="/certif2" element={<PrivatedRoute><Certificat2 /></PrivatedRoute>} />
          <Route path="/suivi-perseverance" element={<PrivatedRoute><Perseverance /></PrivatedRoute>} />
          <Route path="/nc-mes-saisies" element={<PrivatedRoute><ListSaisies /></PrivatedRoute>} />
          <Route path="/suivi-principal" element={<PrivatedRoute><ListAppel /></PrivatedRoute>} />

        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
