import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ContactService from "../../services/contact-service";
import CertificatService from "../../services/certificat-service";
import Certificat2 from "./certificat2";
import jsPDF from "jspdf";
import PDF, { Text, AddPage, Line, Image, Table, Html } from "jspdf-react";
import { Button } from "react-bootstrap";

const Certificat = () => {
  const initialState = [];
  const [contacts, setContacts] = useState(initialState);
  const [isChecked, setIsChecked] = useState(false);
  const checkRefs = useRef([]);
  const [data, setData] = useState([]);
  const [ids, setIds] = useState([]);
  const doc = new jsPDF({
    orientation: "landscape",
    unit: "in",
    format: [4, 2],
  });
  const properties = { header: "Acme" };
  const navigate = useNavigate();

  useEffect(() => {
    ContactService.list().then((datas) => {
      console.log(datas.data);
      const newArray = datas.data.map((a) => ({
        ...{
          id: a.id,
          civilite: a.civilite,
          lastname: a.lastname,
          firstname: a.firstname,
          email: a.email,
          telephone: a.telephone,
          genre: a.genre,
          est_mineur: a.est_mineur,
          metier: a.metier,
          birthdate: a.birthdate,
        },
      }));
      setContacts(newArray);
    });
  }, []);
  const handleChange = (e) => {
    e.persist();
    const contactsCheck = contacts.filter(
      (contact, index) => checkRefs.current[index].checked == true
    );
    const ids = contactsCheck.map((c, i) => ({
      ...{
        id: c.id,
        civilite: c.civilite,
        lastname: c.lastname,
        firstname: c.firstname,
      },
    }));
    console.log(ids);
    setIds(ids);
    setData(contactsCheck);
    setIsChecked(!isChecked);

    if (checkRefs.current.checked) {
      console.log(checkRefs.current);
    }
  };
  const downloadCertif = () => {
    navigate("/certif2", { state: { users: ids } });
  };
  console.log(data);
  return (
    <>
      <div id="main-content">
        <div className="block-header">
          <div className="row clearfix">
            <div className="col-md-6 col-sm-12">
              <h4 className="text-31-secondary">Gestion des Certificats</h4>
            </div>
            <div className="col-md-6 col-sm-12 text-right">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/home">
                    <i className="icon-home"></i>
                  </a>
                </li>
                <li className="breadcrumb-item">Gestion des Certificats</li>
                <li className="breadcrumb-item active">Certificats</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row clearfix">
            <div className="col-lg-12">
              <div className="card">
                <div className="header">
                  <div className="row">
                    <div className="col-md-2">
                      <button
                        onClick={() => {
                          downloadCertif();
                        }}
                        className="btn btn-success"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i className="fas fa-eye" aria-hidden="true">
                          {" "}
                          Afficher
                        </i>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="body">
                  <div className="table-responsive">
                    <table className="table table-striped text-center">
                      <thead>
                        <tr>
                          <th>Id</th>
                          <th>Nom</th>
                          <th>Prénom</th>
                          <th>Email</th>
                          <th>Téléphone</th>
                          <th>Sélectionné</th>
                        </tr>
                      </thead>
                      <tbody>
                        {contacts.map((contact, index) => (
                          <tr key={contact.id}>
                            <td>{contact.id}</td>
                            <td>{contact.lastname}</td>
                            <td>{contact.firstname}</td>
                            <td>{contact.email}</td>
                            <td>{contact.telephone}</td>
                            <td>
                              <input
                                type="checkbox"
                                name="checked"
                                id={contact.id}
                                className="form-check-input"
                                ref={(el) => (checkRefs.current[index] = el)}
                                value={contact.id}
                                onChange={handleChange}
                                defaultChecked={isChecked}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Certificat;
