import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  DataGrid,
  GridToolbar,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  frFR
} from "@mui/x-data-grid";
import { useDemoData } from "@mui/x-data-grid-generator";
import Pagination from "@mui/material/Pagination";
import contactservice from "../../services/contact-service";
import InfoIcon from '@mui/icons-material/Info';
import DetailSession from "../../pages/gestionNc/detail-session";
import EditNc from "../../pages/gestionNc/edit-nc";
import { toast, ToastContainer } from "react-toastify";
import { Edit } from "@mui/icons-material";
import EditUser from "../../pages/users/edit-user";


function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      count={pageCount}
      page={page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

const VISIBLE_FIELDS = [
  "id",
  "lastname",
  "firstname",
  "email",
  "telephone",
  "genre",
  "est_mineur",
  "birthdate",
  "author_id",
  "subscription_date",
  "created_at",
  "ville",
  "secteur_gf",
  "code_postal",
  "adresse_postale",
  "metier",
  "source",
  "source_id",
  "liste_id",
  "actions",
  "libelle_source"
];
const DataTable = ({ users }) => {
  const [pageSize, setPageSize] = React.useState(5);
  const roleId = localStorage.getItem("roleId");
  const columns = [
    { field: "id", sort: 'desc', hide: true, headerName: "Id", flex: 1 },
    { field: "name", headerName: "Nom", flex: 1 },
    { field: "firstname", headerName: "Prenom", flex: 1 },
    { field: "author", hide:true, headerName: "Saisi Par", flex: 1 },
    { field: "subscription_date", hide:true, headerName: "Date de saisie", flex: 1 },
    { field: "created_at", hide:true, headerName: "Créé le", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "mobile_phone", headerName: "Telephone", flex: 1 },
    { field: "genre", hide: true, headerName: "Genre", flex: 1, hideable: true },
    { field: "civil_status", headerName: "Statut", flex: 1 },
    { field: "civilite", hide: true, type: "Civilité", headerName: "Civilité", flex: 1 },
    // { field: "subscription_date", hide: true, type: "Incrit le", headerName: "Inscrit le", flex: 1 },
    { field: "metier", hide: true, type: "", headerName: "Métier", flex: 1 },
    { field: "source_id", hide: true },
    { field: "ville", hide: true },
    { field: "code_postal", hide: true },
    { field: "secteur_gf", hide: true },
    { field: "adresse_postale", hide: true },
    { field: "liste_id", hide: true },
    { field: "is_active", hide: false, type: "Compte", headerName: "Compte", flex: 1 },
    { field: "roles", hide: false, type: "Rôles", headerName: "Rôles", flex: 2 },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 250,
      renderCell: (id) => (
        <>

         <EditUser data = {id.row} id = {id} />

         <div>
          {
          (roleId == 1) &&
            (<Button
              id="deleteBtn"
              style={{
                backgroundColor: "#e8605d",
                color: "#fff",
                padding: "3px 3px",
              }}
              onClick={() => {
                if (
                  window.confirm(
                    "Êtes-vous sûr de vouloir supprimer cet enregistrement ?"
                  )
                ) {
                  handleDelete(id);
                }
              }}
              variant="contained"
              color="primary"
              type="submit"
            >
              <DeleteIcon />
            </Button>)
          }
          </div>
        </>
      ),
    },
  ];

  const { data } = useDemoData({
    dataSet: "Commodity",
    rowLength: 100,
    maxColumns: 6,
    visibleFields: VISIBLE_FIELDS,
  });
  const navigate = useNavigate();
  const handleDelete = (user) => {
    contactservice.remove(user.id).then((data) => console.log(data));
    toast("Enrégistrement supprimé avec succès",
    {
      autoClose: 2000, // Durée d'affichage du toast en millisecondes (2 secondes dans cet exemple)
      onClose: () => {
        window.location.reload(); // Actualisez la page lorsque le toast est fermé
      },
    }
    );
    navigate("/list-nc");
  };
  return (
    <div style={{ height: 450, width: "100%" }}>
      <DataGrid
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        {...data}
        initialState={{
          sorting: {
            sortModel: [{ field: 'id', sort: 'desc' }],
          },
        }}
        components={{ Toolbar: GridToolbar}}
        rows={users}
        columns={columns}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20]}
        pagination
      />
       <ToastContainer />
    </div>
   
  );
};

export default DataTable;
